import { useAuthenticationStore } from '@/stores/authentication/authenticationStore.js'
export default [
  {
    path: '/dashboard',
    children: [
      {
        path: '',
        name: 'defaultRoot',
        beforeEnter: () => {
          return useAuthenticationStore().storeIsLoadPublicDetail(false)
        },
        components: {
          navigationBar: () => import('@/layout/common/body/body-view.vue')
        },
        meta: {
          pageTitle: 'Dashboard',
          requireAuthorization: true,
          permission: ['admin', 'employee', 'student', 'parent']
        }
      }
    ]
  }
]
