import { defineStore } from 'pinia'
import { authenticationEndPoints } from '@/_services/authentication/authentication.service.js'
import cookiesStorage from 'js-cookie'
import { encodeHexa, decodeHexa } from '@/_helpers/crypto.utils.js'
export const useAuthenticationStore = defineStore('authenticationStore', {
  /************************************************************
                              @State Start
    ************************************************************/
  state: () => ({
    institutionPublicDetailState: {},
    institutionDomainDetailState: null,
    /*-------------------------------------------------------------------
     @Start State to stop double API call to Load Public Details
  ----------------------------------------------------------------------*/
    isLoadPublicDetailsState: '74727565'
  }),

  persist: [
    /*-------------------------------------------------------------------
       @Start State to stop double API call to Load Public Details
    ----------------------------------------------------------------------*/
    {
      key: '_zyml_instpblcdts',
      paths: ['institutionPublicDetailState'],
      storage: localStorage
    },
    /*-------------------------------------------------------------------
       @Start State to stop double API call to Load Public Details
    ----------------------------------------------------------------------*/
    {
      key: '_zyml_isldpdt',
      paths: ['isLoadPublicDetailsState'],
      storage: localStorage
      // storage: {
      //   getItem: (key) => {
      //     const encodedValue = cookiesStorage.get(key, {
      //       secure: import.meta.env.VITE_APP_PROD === 'true'
      //     })
      //     return encodedValue ? JSON.parse(encodedValue) : null
      //   },
      //   setItem: (key, value) => {
      //     cookiesStorage.set(key, JSON.stringify(value), {
      //       secure: import.meta.env.VITE_APP_PROD === 'true'
      //     })
      //   },
      //   removeItem: (key) => {
      //     cookiesStorage.remove(key, {
      //       secure: import.meta.env.VITE_APP_PROD === 'true'
      //     })
      //   }
      // }
    }
  ],
  /************************************************************
                              @Getters Start
    ************************************************************/
  getters: {
    getInstitutionDomainDetail(state) {
      return state.institutionDomainDetailState
    },
    getInstitutionPublicDetail(state) {
      return state.institutionPublicDetailState.details
    },

    getInstitutionLocaleDetail(state) {
      return state.institutionPublicDetailState.locale
    },
    /*--------------------------------------------------------------------------------
       @Start DecodedHexa State to stop double API call to Load Public Details
    ---------------------------------------------------------------------------------*/
    getIsLoadPublicDetail(state) {
      return decodeHexa(state.isLoadPublicDetailsState)
    }
  },
  /************************************************************
                @Start Actions for Authentication
   ************************************************************/
  actions: {
    async storeVerifyUserDetail(queryParam) {
      return await authenticationEndPoints.getVerifyUser(queryParam)
    },

    async storeValidateOTP(queryParam) {
      return await authenticationEndPoints.getVerifyOTP(queryParam)
    },

    async storeLogoutDevice() {
      return await authenticationEndPoints.logoutDevice()
    },
    /************************************************************
                @Start Actions for Institution Public Details
     ************************************************************/

    async storeInstitutionPublicDetailByURL() {
      const { isResult = false, data = { details: {}, locale: {} } } =
        await authenticationEndPoints.getInstitutionPublicDetailByURL({
          code: this.institutionDomainDetailState
        })
      if (isResult) return (this.institutionPublicDetailState = data)
    },
    /**********************************************************************
           @Actions Start to get domain
       ***********************************************************************/

    storeGetInstitutionDomain() {
      return (this.institutionDomainDetailState =
        import.meta.env.VITE_APP_PROD === 'true'
          ? `${window.location.protocol}//${window.location.hostname}`
          : 'https://rt.zeymal.in')
    },
    /**********************************************************************
         @Actions Start to stop double API call to Load Public Details
     ***********************************************************************/

    storeIsLoadPublicDetail(payload) {
      try {
        this.isLoadPublicDetailsState = encodeHexa(payload)
        return true
      } catch (error) {
        console.error('Error in storeIsLoadPublicDetail', error)
        throw error
      }
    },

    async mutateAuthenticationInstitutePublicDetails(payload) {
      this.institutionPublicDetailState.details = payload
    }
  }
})
