import { defineStore } from 'pinia'
import { useDashboardStore } from '@/stores/dashboard/dashboardStore.js'
export const useCardStore = defineStore('cardStore', {
  /************************************************************
                        @State Start
     ************************************************************/
  state: () => ({
    /*---------------------------------------
            @Start State for Module Cards
        ----------------------------------------*/
    dynamicModuleCardState: []
  }),

  persist: [
    /*---------------------------------------
            @Start Persist for Dynamic Cards
        ----------------------------------------*/
    {
      key: '_zyml_dyncd',
      paths: ['dynamicModuleCardState'],
      storage: localStorage
    }
  ],

  /************************************************************
                            @Getters Start
    ************************************************************/
  getters: {
    /*-----------------------------------------------------------
                          @Start Load Modules for Dynamic Cards
        -----------------------------------------------------------*/
    getListModule(state) {
      return state.dynamicModuleCardState
    }
  },

  /************************************************************
                          @Actions Start
    ************************************************************/
  actions: {
    storeLoadActiveModule(moduleId) {
      const modules = useDashboardStore().getListUserModules
      const modulesMap = new Map()

      modules.forEach((obj) => {
        modulesMap.set(obj.ash_Id, obj)
      })

      const module = modulesMap.get(parseInt(moduleId))

      return (this.dynamicModuleCardState = module ? module.inverseAsh_Parent : [])
    },

    storeSetActiveModules(modules) {
      this.dynamicModuleCardState = modules
    },

    storeLoadActiveSubModule(moduleId) {
      const modulesMap = new Map()
      const modules = this.dynamicModuleCardState

      modules.forEach((obj) => {
        modulesMap.set(obj.ash_Id, obj)
      })

      const module = modulesMap.get(parseInt(moduleId))

      return (this.dynamicModuleCardState = module ? module.inverseAsh_Parent : [])
    }
  }
})
