export default [
  {
    path: '/receipt-timeline/:receiptID/',
    name: 'receiptTimeline',
    component: () => import('@/timeline/receipt-timeline.vue'),
    meta: {
      pageTitle: 'Receipt Timeline',
      requireAuthorization: false
    }
  }
]
