export default [
  {
    path: '/human-resource',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    children: [
      {
        path: '',
        name: 'humanResource',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Human Resources',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'hr-setting',
        name: 'hrSetting',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Human Resource Settings',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'hr-setting/employee-category',
        name: 'employeeCategory',

        component: () => import('@/views/human-resource/hr-settings/employee-category.vue'),
        meta: {
          pageTitle: 'Employee Category',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'hr-setting/employee-position',
        name: 'employeePosition',

        component: () => import('@/views/human-resource/hr-settings/employee-position.vue'),
        meta: {
          pageTitle: 'Employee Position',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'hr-setting/employee-department',
        name: 'employeeDepartment',

        component: () => import('@/views/human-resource/hr-settings/employee-department.vue'),
        meta: {
          pageTitle: 'Employee Department',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'hr-setting/employee-grade',
        name: 'employeeGrade',

        component: () => import('@/views/human-resource/hr-settings/employee-grade.vue'),
        meta: {
          pageTitle: 'Employee Grade',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'hr-setting/bank-detail',
        name: 'bankDetail',

        component: () =>
          import('@/views/human-resource/employee-management/fragments/bank-details.vue'),
        meta: {
          pageTitle: 'Employee Bank',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'hr-setting/employee-additional-setting',
        name: 'employeeAdditionalSetting',

        component: () =>
          import('@/views/human-resource/employee-management/fragments/additional-details.vue'),
        meta: {
          pageTitle: 'Employee Additional Settings',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'employee-management',
        name: 'employeeManagement',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Employee Management',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'employee-management/employee-admission',

        component: () =>
          import('@/views/human-resource/employee-management/employee-admission.vue'),
        meta: {
          pageTitle: 'Employee Admission',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        },
        children: [
          {
            path: '',
            name: 'employeeAdmission',
            components: {
              step: () =>
                import(
                  '@/views/human-resource/employee-management/fragments/employee-admission-step/general-details.vue'
                )
            },
            meta: {
              pageTitle: 'Employee Admission - General Details',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'contact',
            name: 'contact',
            components: {
              step: () =>
                import(
                  '@/views/human-resource/employee-management/fragments/employee-admission-step/contact-details.vue'
                )
            },
            meta: {
              pageTitle: 'Employee Admission - Contact Details',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'address',
            name: 'address',
            components: {
              step: () =>
                import(
                  '@/views/human-resource/employee-management/fragments/employee-admission-step/address-details.vue'
                )
            },
            meta: {
              pageTitle: 'Employee Admission - Address Details',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'bank',
            name: 'bank',
            components: {
              step: () =>
                import(
                  '@/views/human-resource/employee-management/fragments/employee-admission-step/bank-details.vue'
                )
            },
            meta: {
              pageTitle: 'Employee Admission - Bank Details',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'additional-detail',
            name: 'empAdditionalDetail',
            components: {
              step: () =>
                import(
                  '@/views/human-resource/employee-management/fragments/employee-admission-step/additional-details.vue'
                )
            },
            meta: {
              pageTitle: 'Employee Admission - Additional Details',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },
      {
        path: 'employee-management/employee-search',
        name: 'employeeSearch',

        component: () => import('@/views/human-resource/employee-record/employee-search.vue'),
        meta: {
          pageTitle: 'Employee Search',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'employee-management/employee-detail/:employeeid?/',
        name: 'employeeDetail',

        component: () => import('@/views/human-resource/employee-record/employee-preview.vue'),
        meta: {
          pageTitle: 'Employee Details',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        },
        props: true
      },
      {
        path: 'employee-management/employee-subject-association',
        name: 'employeeSubjectAssociation',
        component: () =>
          import('@/views/human-resource/employee-management/employee-subject-association.vue'),
        meta: {
          pageTitle: 'Employee Subject Association',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        },
        props: true
      }
    ]
  }
]
