import { removeCookies } from '@/_helpers/authentication.utils.js'
/************************************************************
        @Start Encode & Decode Methods
************************************************************/

export function encodeHexa(input) {
  try {
    if (typeof input !== 'object' && typeof input !== 'boolean' && typeof input !== 'string') {
      throw new Error('Input must be an object, boolean, array, or string')
    }

    const jsonString = JSON.stringify(input)
    if (!jsonString) {
      throw new Error('Failed to stringify input')
    }

    const encoder = new TextEncoder()
    const data = encoder.encode(jsonString)

    if (!data || data.length === 0) {
      throw new Error('Failed to encode data')
    }

    const hexArray = Array.from(data, (byte) => byte.toString(16).padStart(2, '0'))
    return caesarEncrypt(hexArray.join(''), 9)
  } catch (error) {
    console.error('Something went wrong in encodeHexa', error)
    removeCookies(null, 500)
    throw error
  }
}

export function decodeHexa(input) {
  try {
    if (typeof input !== 'object' && typeof input !== 'boolean' && typeof input !== 'string') {
      throw new Error('Input must be an object, boolean, array, or string')
    }

    const hexCode = caesarDecrypt(input, 9)
    if (hexCode.length % 2 !== 0) {
      throw new Error('Hex string length must be even')
    }

    const dataArray = new Uint8Array(hexCode.length / 2)

    for (let i = 0; i < dataArray.length; i++) {
      dataArray[i] = parseInt(hexCode.substr(i * 2, 2), 16)
      if (isNaN(dataArray[i])) {
        throw new Error('Failed to decode hex')
      }
    }

    const decoder = new TextDecoder('utf-8')
    const jsonStringBack = decoder.decode(dataArray)

    if (!jsonStringBack) {
      throw new Error('Failed to decode data')
    }

    return JSON.parse(jsonStringBack)
  } catch (error) {
    console.error('Something went wrong in decodeHexa', error)
    removeCookies(null, 500)
    throw error
  }
}

export function convertToBase64(input) {
  try {
    const base64String = btoa(input)
    return base64String
  } catch (error) {
    console.error('Error converting to Base64:', error.message)
    return null
  }
}

/************************************************************
        @Start CaesarEncrypt Methods
************************************************************/
function caesarEncrypt(text, shift) {
  if (typeof text !== 'string' || typeof shift !== 'number') {
    throw new Error('Invalid input types')
  }

  let result = ''

  for (let i = 0; i < text.length; i++) {
    let char = text[i]

    if (char.match(/[a-z]/i)) {
      const code = text.charCodeAt(i)
      const isUpperCase = char === char.toUpperCase()
      const shiftedCode = (code - (isUpperCase ? 65 : 97) + shift) % 26
      char = String.fromCharCode(
        (shiftedCode >= 0 ? shiftedCode : shiftedCode + 26) + (isUpperCase ? 65 : 97)
      )
    }

    result += char
  }

  return result
}

function caesarDecrypt(ciphertext, shift) {
  return caesarEncrypt(ciphertext, 26 - shift)
}
/************************************************************
          @Start Encryption And Decryption Methods for Array
  ************************************************************/
/**
 * Encrypts an array using AES-GCM encryption.
 * @param {Array} array - The array to be encrypted.
 * @param {string} password - The password for encryption.
 * @returns {Promise<{ encryptedData: ArrayBuffer, iv: Uint8Array }>} - The encrypted data and initialization vector.
 */
export async function encryptArray(array, password) {
  try {
    const encodedData = new TextEncoder().encode(JSON.stringify(array))

    const passwordKey = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(password))

    const key = await crypto.subtle.importKey('raw', passwordKey, { name: 'AES-GCM' }, false, [
      'encrypt'
    ])

    const iv = crypto.getRandomValues(new Uint8Array(16))

    const encryptedData = await crypto.subtle.encrypt({ name: 'AES-GCM', iv }, key, encodedData)

    return { encryptedData, iv }
  } catch (error) {
    console.error('Error in encryptArray:', error)
    throw error
  }
}
/**
 * Decrypts an array encrypted with AES-GCM encryption.
 * @param {ArrayBuffer} encryptedData - The encrypted data.
 * @param {Uint8Array} iv - The initialization vector.
 * @param {string} password - The password for decryption.
 * @returns {Promise<Array>} - The decrypted array.
 */
export async function decryptArray(encryptedData, iv, password) {
  try {
    const passwordKey = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(password))

    const key = await crypto.subtle.importKey('raw', passwordKey, { name: 'AES-GCM' }, false, [
      'decrypt'
    ])

    const encryptedDataBuffer =
      encryptedData instanceof ArrayBuffer ? encryptedData : encryptedData.buffer

    const decryptedData = await crypto.subtle.decrypt(
      { name: 'AES-GCM', iv },
      key,
      encryptedDataBuffer
    )

    const decodedData = new TextDecoder().decode(decryptedData)
    return JSON.parse(decodedData)
  } catch (error) {
    console.error('Error in decryptArray:', error)
    throw error
  }
}
