export default [
  {
    path: 'report-settings',
    children: [
      {
        path: '',
        name: 'reportSettings',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Report Settings',
          requireAuthorization: true
        }
      },

      {
        path: 'module-template',
        name: 'moduleTemplate',
        component: () => import('@/views/configuration/template-settings/module-template.vue'),
        meta: {
          pageTitle: 'Module Template',
          requireAuthorization: true
        }
      },
      {
        path: 'general-settings',
        name: 'templateGeneralSettings',
        component: () => import('@/views/configuration/template-settings/general-settings.vue'),
        meta: {
          pageTitle: 'General Settings',
          requireAuthorization: true
        }
      },
      {
        path: 'pdf-settings',
        name: 'templatePdfSettings',
        component: () => import('@/views/configuration/template-settings/pdf-settings.vue'),
        meta: {
          pageTitle: 'PDF Settings',
          requireAuthorization: true
        }
      },
      {
        path: 'printer-settings',
        name: 'templatePrinterSettings',
        component: () => import('@/views/configuration/template-settings/printer-settings.vue'),
        meta: {
          pageTitle: 'Printer Settings',
          requireAuthorization: true
        }
      },
      {
        path: 'generate-template',
        name: 'templateGenerate',
        component: () => import('@/views/configuration/template-settings/generate-template.vue'),
        meta: {
          pageTitle: 'Generate Template',
          requireAuthorization: true
        }
      }
    ]
  }
]
