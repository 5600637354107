import { NewDomHandler } from '../utils/Utils.js'
import BaseRipple from './BaseRipple'

const Ripple = BaseRipple.extend('ripple', {
  mounted(el) {
    const config = el?.$instance?.$config

    if (config && config.ripple) {
      this.create(el)
      this.bindEvents(el)

      el.setAttribute('data-pd-ripple', true)
    }
  },
  unmounted(el) {
    this.remove(el)
  },
  timeout: undefined,
  methods: {
    bindEvents(el) {
      el.addEventListener('mousedown', this.onMouseDown.bind(this))
    },
    unbindEvents(el) {
      el.removeEventListener('mousedown', this.onMouseDown.bind(this))
    },
    create(el) {
      const ink = NewDomHandler.createElement('span', {
        role: 'presentation',
        'aria-hidden': true,
        'data-p-ink': true,
        'data-p-ink-active': false,
        class: !this.isUnstyled() && this.cx('root'),
        onAnimationEnd: this.onAnimationEnd.bind(this),
        'p-bind': this.ptm('root')
      })

      el.appendChild(ink)

      this.$el = ink
    },
    remove(el) {
      let ink = this.getInk(el)

      if (ink) {
        this.unbindEvents(el)
        ink.removeEventListener('animationend', this.onAnimationEnd)
        ink.remove()
      }
    },
    onMouseDown(event) {
      let target = event.currentTarget
      let ink = this.getInk(target)

      if (!ink || getComputedStyle(ink, null).display === 'none') {
        return
      }

      !this.isUnstyled() && NewDomHandler.removeClass(ink, 'p-ink-active')
      ink.setAttribute('data-p-ink-active', 'false')

      if (!NewDomHandler.getHeight(ink) && !NewDomHandler.getWidth(ink)) {
        let d = Math.max(NewDomHandler.getOuterWidth(target), NewDomHandler.getOuterHeight(target))

        ink.style.height = d + 'px'
        ink.style.width = d + 'px'
      }

      let offset = NewDomHandler.getOffset(target)
      let x = event.pageX - offset.left + document.body.scrollTop - NewDomHandler.getWidth(ink) / 2
      let y = event.pageY - offset.top + document.body.scrollLeft - NewDomHandler.getHeight(ink) / 2

      ink.style.top = y + 'px'
      ink.style.left = x + 'px'

      !this.isUnstyled() && NewDomHandler.addClass(ink, 'p-ink-active')
      ink.setAttribute('data-p-ink-active', 'true')

      this.timeout = setTimeout(() => {
        if (ink) {
          !this.isUnstyled() && NewDomHandler.removeClass(ink, 'p-ink-active')
          ink.setAttribute('data-p-ink-active', 'false')
        }
      }, 401)
    },
    onAnimationEnd(event) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      !this.isUnstyled() && NewDomHandler.removeClass(event.currentTarget, 'p-ink-active')
      event.currentTarget.setAttribute('data-p-ink-active', 'false')
    },
    getInk(el) {
      return el && el.children
        ? [...el.children].find(
            (child) => NewDomHandler.getAttribute(child, 'data-pc-name') === 'ripple'
          )
        : undefined
    }
  }
})

export default Ripple
