import { createRouter, createWebHistory } from 'vue-router'
import { useDashboardStore } from '@/stores/dashboard/dashboardStore.js'
import { useCardStore } from '@/stores/card/cardStore.js'
import applicantRoutes from './router-employee/applicant/applicant.route.js'
import { usePermissionStore } from '@/stores/permission/permissionStore.js'
/*xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  @Start Common Routes Imports for Student || Parent || Employee || Admin
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx*/

import authenticationRoute from './authentication/authentication.route.js'

import errorsRoute from './errors/error.route.js'

import embedRoute from './embed/embed-route.js'

import timelineRoute from './timeline/timeline.route.js'

import dashboardRoute from './dashboard/dashboard.route.js'

import modulesRoute from './permission/permission.route.js'

/*xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            @Start Admin || Employee  Routes
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx*/

/************************************************************
        @Start User Privilege Imports
************************************************************/

import userPrivilegeRoute from './router-employee/user/user-privilege.route.js'

/************************************************************
        @Start Student Imports
************************************************************/

import StudentRoute from './router-employee/student/student.route.js'

/************************************************************
        @Start Configuration Imports
************************************************************/

import configurationSettingRoute from './router-employee/configuration/configuration-setting.route.js'

/************************************************************
        @Start Human Resource Imports
************************************************************/
import humanResourceRoute from './router-employee/human-resource/human-resource.route.js'

/************************************************************
        @Start Subject Center Imports
************************************************************/
import subjectCenterRoute from './router-employee/subject-center/subject-center.route.js'

/************************************************************
        @Start Examination Imports
************************************************************/

/************************************************************
        @Start Finance Imports
************************************************************/

import financeRoute from './router-employee/finance/finance.route.js'

/************************************************************
        @Start REPORTS Imports
************************************************************/

import reportsCenterRoute from './router-employee/report/report.route.js'

/************************************************************
        @Start GRADEBOOK Imports
************************************************************/

import gradebookRoute from './router-employee/gradebook/gradebook.route.js'

/************************************************************
        @Start COLLABORATION Imports
************************************************************/

import alumniRoute from './router-employee/collaboration/alumni.route.js'

/************************************************************
        @Start NEWS Imports
************************************************************/
import newsRoute from './router-employee/collaboration/news.route.js'

/************************************************************
        @Start Discussion Imports
************************************************************/
import discussionRoute from './router-employee/collaboration/discussion.route.js'

/************************************************************
        @Start Gallery Imports
************************************************************/
import galleryRoute from './router-employee/collaboration/gallery.route.js'

/************************************************************
        @Start Poll Imports
************************************************************/
import pollRoute from './router-employee/collaboration/poll.route.js'

/************************************************************
        @Start Custom Imports
************************************************************/
import customImportRoute from './router-employee/custom-import/custom-import.route.js'

/************************************************************
        @Start Attendance Imports
************************************************************/
import attendanceRoute from './router-employee/attendance/attendance.route.js'

/************************************************************
        @Start Communication Imports
************************************************************/
import communicationRoute from './router-employee/communication/communication.route.js'

/************************************************************
        @Start Transport Imports
************************************************************/
import transportRoute from './router-employee/transport/transport.route.js'

/************************************************************
        @Start Academic Management Imports
************************************************************/
import academicManagementRoute from './router-employee/academic-management/academic-management.route.js'

/*xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            @Start Student || Parent Routes
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx*/

/************************************************************
        @Start Middleware for Validation
************************************************************/
import { checkAuthentication } from '@/_helpers/authentication.utils.js'

const routes = [
  ...authenticationRoute,

  ...dashboardRoute,

  ...configurationSettingRoute,

  ...humanResourceRoute,

  ...subjectCenterRoute,

  ...StudentRoute,

  ...applicantRoutes,

  ...userPrivilegeRoute,

  ...financeRoute,

  ...reportsCenterRoute,

  ...gradebookRoute,

  ...alumniRoute,

  ...customImportRoute,

  ...attendanceRoute,

  ...communicationRoute,

  ...errorsRoute,

  ...embedRoute,

  ...timelineRoute,

  ...newsRoute,

  ...discussionRoute,

  ...pollRoute,

  ...galleryRoute,

  ...transportRoute,

  ...academicManagementRoute,

  ...modulesRoute
]

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_PATH),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { selector: to.hash }
    } else {
      return { left: 0, top: 0 }
    }
  }
})

/************************************************************
        @Start Route Hooks
************************************************************/

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = checkAuthentication()
  if (isAuthenticated && from.name === 'verifyUser' && to.path === '/dashboard') {
    useDashboardStore().storeIsLoadDefaultConfiguration(true)
    await useDashboardStore().storeLoadListDefaultConfiguration()
    usePermissionStore().storeLoadModuleActionPermission(null)
  } else if (isAuthenticated && to.path === '/dashboard') {
    useDashboardStore().storeIsLoadDefaultConfiguration(false)
    usePermissionStore().storeLoadModuleActionPermission(null)
  }

  const requiredPermissions = to.meta.permission
  if (to.matched.length === 0) return next({ name: 'error404' })
  if (to.meta.guestOnly && isAuthenticated) return next({ name: 'defaultRoot' })
  if (to.meta.requireAuthorization) {
    if (!isAuthenticated) {
      return next({
        name: 'verifyUser',
        query: { message: 'Unauthorized', code: 401 },
        replace: true
      })
    }
    if (!from.name && to.name.includes('error403')) await router.replace('/dashboard')
    if (!requiredPermissions) return next()
    if (
      requiredPermissions.includes(
        (useDashboardStore().getAuthorizedUserDetail.ash_RoleName || '').toLowerCase()
      )
    )
      return next()
    else {
      next({
        name: 'error403',
        query: { message: 'Forbidden', code: 403 },
        replace: true
      })
      return setTimeout(() => {
        from.path ? router.push(from.path) : router.push('/dashboard')
      }, 3000)
    }
  }

  return next()
})

router.beforeResolve(async (to) => {
  await removeUnnecessaryState(to)
  setActiveCards(to)
})

router.afterEach(async (to) => {
  if (to.meta.pageTitle) document.title = to.meta.pageTitle
})

function setActiveCards(to) {
  let Modules = useDashboardStore().getListUserModules
  if (Modules) {
    const childModules = recursiveSearch(Modules, to.name)
    useCardStore().storeSetActiveModules(childModules ? childModules?.inverseAsh_Parent : [])
  }
  return
}

router.onError(async (error) => {
  if (
    error &&
    (error.message.includes('Cannot read properties of null') ||
      error.message.includes('No match for'))
  )
    console.error('Error in onError', error.message)
})

async function removeUnnecessaryState(to) {
  try {
    const localStoreToRemove = []
    const excludeUserRoutes = ['userPrivilege']
    const excludeExamPlannerRoutes = [
      'assessmentPlan',
      'assessmentTypeExam',
      'termTypeExam',
      'plannerTypeExam'
    ]
    const excludeManageGradebookRoutes = [
      'manageExamDetail',
      'editScheduledExam',
      'scheduleSubjectExamInitialStep',
      'scheduleSubjectExamFinalStep',
      'studentsActivityMarkEntry',
      'examMarksAndRemarks',
      'examResult',
      'manageSubjectExamBulkMarksImport'
    ]
    const excludeExamDetailRoutes = [
      'editScheduledExam',
      'examMarksAndRemarks',
      'studentOverallResultSheet',
      'scheduledBatchResultSheet'
    ]
    const excludeCustomImport = ['studentAdmissionVerifyModel', 'employeeAdmissionVerifyModel']
    /**************************************************
    @Start States to be removed for faster processing!
    **************************************************/

    if (!excludeUserRoutes.includes(to.name)) localStoreToRemove.push('_zyml_usrtypemplydt')

    if (!excludeExamPlannerRoutes.includes(to.name))
      localStoreToRemove.push('_zyml_assmnt_pln_exm_dtl', '_zyml_assmnt_pln')

    if (!excludeManageGradebookRoutes.includes(to.name))
      localStoreToRemove.push('_zyml_mng_exm_schd_exm')

    if (!excludeExamDetailRoutes.includes(to.name))
      localStoreToRemove.push('_zyml_mng_exm_schd_exm_edtadsbjt', '_zyml_mng_exm_rslt_sht_prfd')

    if (!excludeCustomImport.includes(to.name)) localStoreToRemove.push('_zyml_upldSdD')

    localStoreToRemove.forEach((key) => {
      if (localStorage.getItem(key) !== null) {
        localStorage.removeItem(key)
      }
      if (sessionStorage.getItem(key) !== null) {
        sessionStorage.removeItem(key)
      }
    })
  } catch (error) {
    console.error('Error in removeUnnecessaryState', error)
  }
}

function recursiveSearch(array, targetName) {
  for (let i = 0; i < array.length; i++) {
    const item = array[i]
    if (item.ash_PathName === targetName) {
      usePermissionStore().storeLoadModuleActionPermission(item.ash_Id)

      return item
    } else if (item.inverseAsh_Parent) {
      const result = recursiveSearch(item.inverseAsh_Parent, targetName)
      if (result !== null) {
        return result
      }
    }
  }
  return null
}
export default router
