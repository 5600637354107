export default [
  {
    path: 'transport-fee-control',
    children: [
      {
        path: '',
        name: 'transportFeeControl',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Transport Fee Control',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'transport-manage-transport-fee-settings',
        children: [
          {
            path: '',
            name: 'transportFeeSettings',
            component: () => import('@/views/dynamic-card.vue'),
            meta: {
              pageTitle: 'Transport Fee Settings',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'transport-fee-general-settings',
            name: 'transportFeeGeneralSettings',
            component: () =>
              import(
                '@/views/transport/fee-control/manage-transport-fee-setting/transport-fee-general-setting.vue'
              ),
            meta: {
              pageTitle: 'Transport Fee General Settings',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'transport-receipt-and-voucher',
            name: 'transportReceiptAndVoucher',
            component: () =>
              import('@/views/receipt-and-voucher/transport-receipt-and-voucher.vue'),
            meta: {
              pageTitle: 'Transport Receipt and Voucher',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'transport-fee-template',
            name: 'transportFeeTemplate',
            component: () =>
              import(
                '@/views/transport/fee-control/manage-transport-fee-setting/transport-fee-template.vue'
              ),
            meta: {
              pageTitle: 'Transport Fee Template',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },
      {
        path: 'transport-discount',
        children: [
          {
            path: '',
            name: 'transportDiscount',
            component: () => import('@/views/dynamic-card.vue'),
            meta: {
              pageTitle: 'Transport Discount',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'transport-particular-discount',
            name: 'transportParticularDiscount',
            component: () =>
              import(
                '@/views/transport/fee-control/manage-transport-discount/transport-particular-discount.vue'
              ),
            meta: {
              pageTitle: 'Transport Particular Discount',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'transport-master-discount',
            name: 'transportMasterDiscount',
            component: () =>
              import(
                '@/views/transport/fee-control/manage-transport-discount/transport-master-discount.vue'
              ),
            meta: {
              pageTitle: 'Transport Master Discount',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },
      {
        path: 'create-transport-category',
        children: [
          {
            path: '',
            name: 'createTransportCategory',
            component: () => import('@/views/transport/fee-control/create-transport-category.vue'),
            meta: {
              pageTitle: 'Create Transport Category',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },

      {
        path: 'schedule-transport-fee-collection',
        children: [
          {
            path: '',
            name: 'scheduleTransportFeeCollection',
            component: () => import('@/views/dynamic-card.vue'),
            meta: {
              pageTitle: 'Schedule Transport Fee Collection',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'transport-fee-collection',
            children: [
              {
                path: '',
                name: 'transportFeeCollection',
                component: () =>
                  import(
                    '@/views/transport/fee-control/manage-schedule-transport-fee-collection/transport-fee-collection.vue'
                  ),
                meta: {
                  pageTitle: 'Transport Fee Collection',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'batch-and-department-collection/:transportFeeCollectionID',
                name: 'batchAndDepartmentCollection',
                component: () =>
                  import(
                    '@/views/transport/fee-control/manage-schedule-transport-fee-collection/batch-and-department-collection.vue'
                  ),
                props: true,
                meta: {
                  pageTitle: 'Batch And Department Collection',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'batch-and-department-collection/:transportFeeCollectionID/user-transport-fee-collection/:batchCollectionID?/:departmentCollectionID?',
                name: 'userTransportFeeCollection',
                component: () =>
                  import(
                    '@/views/transport/fee-control/manage-schedule-transport-fee-collection/user-transport-fee-collection.vue'
                  ),
                props: true,
                meta: {
                  pageTitle: 'User Transport Fee Collection',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              }
            ]
          }
        ]
      },
      {
        path: 'user-transport-fee-submission',
        children: [
          {
            path: '',
            name: 'userTransportFeeSubmission',
            component: () =>
              import(
                '@/views/transport/fee-control/manage-transport-fee-submission/user-transport-fee-submission.vue'
              ),
            meta: {
              pageTitle: 'User Transport Fee Submission',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'user-transport-fee-submission-collection-wise/:studentId?/:studentName?',
            name: 'userTransportFeeSubmissionCollectionWise',
            props: true,
            component: () =>
              import(
                '@/views/transport/fee-control/manage-transport-fee-submission/user-transport-fee-submission-collection-wise.vue'
              ),
            meta: {
              pageTitle: 'User Transport Fee Submission Collection Wise',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },
      /************************************************************
              @Routes Start Transport Fee Detail
            ************************************************************/
      {
        path: 'transport-fee-detail',
        children: [
          {
            path: '',
            name: 'transportFeeDetail',
            component: () =>
              import('@/views/finance/fees/fee-detail/program-or-level-fee-collection-detail.vue'),
            meta: {
              pageTitle: 'Transport Fee Detail',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'level-fee/:programId?',
            name: 'transportLevelFeeDetail',
            props: true,
            component: () => import('@/views/finance/fees/fee-detail/fragments/level-fee.vue'),
            meta: {
              pageTitle: 'Transport Level Fee',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'level-fee/:programId?/batch-fee/:levelId?/section-fee/:batchId?',
            name: 'transportSectionFeeDetail',
            props: true,
            component: () =>
              import('@/views/finance/fees/fee-detail/fragments/section/section-fee.vue'),
            meta: {
              pageTitle: 'Transport Section Fee',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          /************************************************************
                  @Routes Start for Students Details || Collections Details
                  ************************************************************/
          {
            path: 'level-fee/:programId?/batch-fee/:levelId?/section-fee/:batchId?/section-students-collection/:sectionID?',
            name: 'transportStudentsFeeDetail',
            props: true,
            component: () =>
              import(
                '@/views/finance/fees/fee-detail/fragments/section/section-students-collection.vue'
              ),
            meta: {
              pageTitle: 'Transport Section Students Collection',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'level-fee/:programId?/batch-fee/:levelId?/section-fee/:batchId?/section-detailed-collection/:sectionID?',
            name: 'transportCollectionsFeeDetail',
            props: true,
            component: () => import('@/views/finance/fees/fee-detail/detailed-collection.vue'),
            meta: {
              pageTitle: 'Transport Section Detailed Collection',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          /************************************************************
                   @Routes End for Students Details || Collections Details
                   ************************************************************/
          {
            path: 'level-fee/:programId?/batch-fee/:levelId?/section-fee/:batchId?/section-individual-collection/:sectionId?',
            name: 'transportFeeCollectionBySection',
            props: true,
            component: () =>
              import(
                '@/views/finance/fees/fee-detail/fragments/section/section-individual-collection.vue'
              ),
            meta: {
              pageTitle: 'Transport Section Individual Collection',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'level-fee/:programId?/batch-fee/:levelId?/section-fee/:batchId?/section-individual-collection/:sectionId?/individual-collection-students/:collectionId?',
            name: 'transportStudentWiseFeeDetail',
            props: true,
            component: () =>
              import('@/views/finance/fees/fee-detail/fragments/student-individual-collection.vue'),
            meta: {
              pageTitle: 'Transport Individual Collection Students',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },
      /************************************************************
                  @Routes Start Fee Report
                ************************************************************/
      {
        path: 'transport-fee-report',
        children: [
          {
            path: '',
            name: 'transportFeeReport',
            component: () => import('@/views/finance/fees/fee-reports/fee-report.vue'),
            meta: {
              pageTitle: 'Transport Fee Report',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'transport-student-wise-fee-report/:studentWiseFeeReportid',
            name: 'transportStudentWiseFeeReport',
            component: () => import('@/views/finance/fees/fee-reports/student-wise-fee-report.vue'),
            meta: {
              pageTitle: 'Transport Student Wise Fee Report',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      }
    ]
  }
]
