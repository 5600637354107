export default [
  {
    path: 'chart-of-account',
    children: [
      {
        path: '',
        name: 'chartOfAccount',
        component: () =>
          import('@/views/finance/finance-main/chart-of-account/chart-of-account.vue'),
        meta: {
          pageTitle: 'Chart of Account',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'group/:parentID?/',
        children: [
          {
            path: '',
            name: 'chartOfAccountGroup',

            component: () =>
              import('@/views/finance/finance-main/chart-of-account/chart-of-account-group.vue'),
            meta: {
              pageTitle: 'Chart of Account Group',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'ledger/:accountID/',
            name: 'chartOfAccountGroupLedger',

            component: () =>
              import('@/views/finance/finance-main/chart-of-account/chart-of-account-ledger.vue'),
            meta: {
              pageTitle: 'Chart of Account Group Ledger',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },

      {
        path: 'ledger/:accountID/',
        name: 'chartOfAccountLedger',
        component: () =>
          import('@/views/finance/finance-main/chart-of-account/chart-of-account-ledger.vue'),
        meta: {
          pageTitle: 'Chart of Account Ledger',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      }
    ]
  }
]
