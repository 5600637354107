export default [
  {
    path: '/subject-center',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    meta: {
      pageTitle: 'Subject Center',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    },
    children: [
      {
        path: '',
        name: 'subjectCenter',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Subject Center',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'course-subject',
        name: 'courseSubject',
        component: () => import('@/views/subject-center/course-subject/course-subject.vue'),
        meta: {
          pageTitle: 'Course Subject',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'link-batch',
        name: 'linkBatch',
        component: () => import('@/views/subject-center/link-batch/link-batch.vue'),
        meta: {
          pageTitle: 'Link Batch',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'manage-subject',
        name: 'manageSubject',
        component: () => import('@/views/subject-center/manage-subject/manage-subject.vue'),
        meta: {
          pageTitle: 'Manage Subject',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'subject-skill-set',
        name: 'subjectSkillSet',
        component: () => import('@/views/subject-center/skill-set/subject-skill-set.vue'),
        meta: {
          pageTitle: 'Subject Skill Set',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'subject-skill-set/subject-skill/:skillSetId?/',
        name: 'subjectSkill',
        component: () => import('@/views/subject-center/skill-set/subject-skill.vue'),
        meta: {
          pageTitle: 'Subject Skill',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'subject-skill-set/subject-skill/:skillSetId?/subject-sub-skill/:subSkillId?/',
        name: 'subjectSubSkill',
        component: () => import('@/views/subject-center/skill-set/subject-sub-skill.vue'),
        meta: {
          pageTitle: 'Subject Sub Skill',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      }
    ]
  }
]
