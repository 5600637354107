import { JSON_REQUEST } from '@/_helpers/connection'

/************************************************************
        @Start Default Configuration
************************************************************/

async function getAllDefaultConfiguration() {
  return await JSON_REQUEST().get('Configuration/LoadDefaultConfiguration')
}

async function updateProgramStatus(payload) {
  return await JSON_REQUEST().post('Configuration/UpdateMasterSettingDetails', payload)
}
/*-----------------------------
   @Start Custom Program Dropdown
-------------------------------*/

async function updateCustomProgramDropdown(queryParam) {
  return await JSON_REQUEST(queryParam).put('Configuration/UpdateCustomProgramStatus')
}

export const dashboardEndPoints = {
  getAllDefaultConfiguration,

  updateProgramStatus,

  updateCustomProgramDropdown
}

/************************************************************
        @End Default Configuration
************************************************************/
