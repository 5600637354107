export default [
  {
    path: 'program-management',
    name: 'programManagement',
    component: () => import('@/views/dynamic-card.vue'),
    meta: {
      pageTitle: 'Program Management',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },
  /***********************************************************************
   * @Start Routes From Program > Level > Batch > Section When Program is ON
   ***********************************************************************/
  {
    path: 'program',
    name: 'programOn',
    component: () =>
      import('@/views/academic-management/manage-program/program-management-program.vue'),
    meta: {
      pageTitle: 'Manage Program',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'program/level/:levelid?/',
    name: 'programOnLevel',
    component: () =>
      import('@/views/academic-management/manage-program/program-management-program-level.vue'),
    meta: {
      pageTitle: 'Manage Level',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'program/level/:programid?/batch/:levelid?/',
    name: 'programOnBatch',
    component: () =>
      import('@/views/academic-management/manage-program/program-management-program-batch.vue'),
    meta: {
      pageTitle: 'Manage Batch',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'program/level/:programid?/batch/:levelid?/section/:batchid?/',
    name: 'programOnSection',
    component: () =>
      import('@/views/academic-management/manage-program/program-management-program-section.vue'),
    meta: {
      pageTitle: 'Manage Section',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  /***********************************************************************
   * @Start Routes From Batch > Section When Program is ON
   ***********************************************************************/

  {
    path: 'batch',
    name: 'batch',
    component: () =>
      import('@/views/academic-management/manage-program/program-management-program-batch.vue'),
    meta: {
      pageTitle: 'Manage Batch',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'batch/section/:programid?/:batchid?/',
    name: 'section',
    component: () =>
      import('@/views/academic-management/manage-program/program-management-program-section.vue'),
    meta: {
      pageTitle: 'Manage Section',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  /***********************************************************************
   * @Start Routes From Level > Batch > Section When Program is OFF
   ***********************************************************************/
  {
    path: 'level',
    name: 'programOffLevel',
    component: () =>
      import('@/views/academic-management/manage-program/program-management-program-level.vue'),
    meta: {
      pageTitle: 'Manage Level',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'level/batch/:levelid?/',
    name: 'programOffBatch',
    component: () =>
      import('@/views/academic-management/manage-program/program-management-program-batch.vue'),
    meta: {
      pageTitle: 'Manage Batch',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'home-room-association',
    name: 'homeRoomAssociation',
    component: () => import('@/views/academic-management/home-room/home-room-association.vue'),
    meta: {
      pageTitle: 'Home Room Association',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  }
]
