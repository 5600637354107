export default [
  {
    path: '/news',
    name: '',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    children: [
      {
        path: '',
        name: 'news',
        component: () => import('@/views/collaboration/news/news.vue'),
        meta: {
          pageTitle: 'Latest Announcements',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'read-news',
        name: 'readNews',
        component: () => import('@/views/collaboration/news/read-news.vue'),
        meta: {
          pageTitle: 'Read News',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      }
    ]
  }
]
