export default [
  {
    path: '/communication',

    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    children: [
      {
        path: '',
        name: 'communication',
        component: () => import('@/views/communication/communication.vue'),
        meta: {
          pageTitle: 'Communication',
          requireAuthorization: true,
          permission: ['admin', 'employee', 'student', 'parent']
        }
      },
      {
        path: 'settings',
        name: 'communicationSettings',
        component: () => import('@/views/communication/settings/settings.vue'),
        meta: {
          pageTitle: 'Communication Settings',
          requireAuthorization: true,
          permission: ['admin', 'employee', 'student', 'parent']
        }
      },
      {
        path: 'create-broadcast-message',
        name: 'createBroadcastMessage',
        component: () => import('@/views/communication/create-broadcast-message.vue'),
        meta: {
          pageTitle: 'Broadcast Message',
          requireAuthorization: true,
          permission: ['admin', 'employee', 'student', 'parent']
        }
      }
    ]
  }
]
