export default [
  {
    path: '/embedded-enquiry',
    name: 'embeddedEnquiry',
    component: () => import('@/embed/embedded-enquiry-form.vue'),
    meta: {
      pageTitle: 'Embedded Enquiry',
      requireAuthorization: false
    }
  }
]
