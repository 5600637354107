import { FilterMatchMode } from '../api/Api.js'
import { inject, reactive } from 'vue'
import { useAuthenticationStore } from '@/stores/authentication/authenticationStore.js'

const defaultOptions = {
  ripple: false,
  inputStyle: null,
  locale: {
    csvUploadSize: 512,
    startsWith: 'Starts with',
    contains: 'Contains',
    notContains: 'Not contains',
    endsWith: 'Ends with',
    equals: 'Equals',
    notEquals: 'Not equals',
    noFilter: 'No Filter',
    lt: 'Less than',
    lte: 'Less than or equal to',
    gt: 'Greater than',
    gte: 'Greater than or equal to',
    dateIs: 'Date is',
    dateIsNot: 'Date is not',
    dateBefore: 'Date is before',
    dateAfter: 'Date is after',
    clear: 'Clear',
    apply: 'Apply',
    matchAll: 'Match All',
    matchAny: 'Match Any',
    addRule: 'Add Rule',
    removeRule: 'Remove Rule',
    accept: 'Yes',
    reject: 'No',
    choose: 'Choose',
    upload: 'Upload',
    cancel: 'Cancel',
    completed: 'Completed',
    pending: 'Pending',
    fileSizeTypes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    monthNamesShort: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    chooseYear: 'Choose Year',
    chooseMonth: 'Choose Month',
    chooseDate: 'Choose Date',
    prevDecade: 'Previous Decade',
    nextDecade: 'Next Decade',
    prevYear: 'Previous Year',
    nextYear: 'Next Year',
    prevMonth: 'Previous Month',
    nextMonth: 'Next Month',
    prevHour: 'Previous Hour',
    nextHour: 'Next Hour',
    prevMinute: 'Previous Minute',
    nextMinute: 'Next Minute',
    prevSecond: 'Previous Second',
    nextSecond: 'Next Second',
    am: 'am',
    pm: 'pm',
    today: 'Today',
    weekHeader: 'Wk',
    firstDayOfWeek: 0,
    showMonthAfterYear: false,
    hourFormat: '12',
    currencySymbol: '₹',
    dateFormat: 'dd-mm-yy',
    weak: 'Weak',
    medium: 'Medium',
    strong: 'Strong',
    passwordPrompt: 'Enter a password',
    emptyFilterMessage: 'No results found',
    searchMessage: '{0} results are available',
    selectionMessage: '{0} items selected',
    emptySelectionMessage: 'No selected item',
    emptySearchMessage: 'No results found',
    emptyMessage: 'No available options'
  },
  aria: {
    trueLabel: 'True',
    falseLabel: 'False',
    nullLabel: 'Not Selected',
    star: '1 star',
    stars: '{star} stars',
    selectAll: 'All items selected',
    unselectAll: 'All items unselected',
    close: 'Close',
    previous: 'Previous',
    next: 'Next',
    navigation: 'Navigation',
    scrollTop: 'Scroll Top',
    moveTop: 'Move Top',
    moveUp: 'Move Up',
    moveDown: 'Move Down',
    moveBottom: 'Move Bottom',
    moveToTarget: 'Move to Target',
    moveToSource: 'Move to Source',
    moveAllToTarget: 'Move All to Target',
    moveAllToSource: 'Move All to Source',
    pageLabel: 'Page {page}',
    firstPageLabel: 'First Page',
    lastPageLabel: 'Last Page',
    nextPageLabel: 'Next Page',
    prevPageLabel: 'Previous Page',
    rowsPerPageLabel: 'Rows per page',
    jumpToPageDropdownLabel: 'Jump to Page Dropdown',
    jumpToPageInputLabel: 'Jump to Page Input',
    selectRow: 'Row Selected',
    unselectRow: 'Row Unselected',
    expandRow: 'Row Expanded',
    collapseRow: 'Row Collapsed',
    showFilterMenu: 'Show Filter Menu',
    hideFilterMenu: 'Hide Filter Menu',
    filterOperator: 'Filter Operator',
    filterConstraint: 'Filter Constraint',
    editRow: 'Row Edit',
    saveEdit: 'Save Edit',
    cancelEdit: 'Cancel Edit',
    listView: 'List View',
    gridView: 'Grid View',
    slide: 'Slide',
    slideNumber: '{slideNumber}',
    zoomImage: 'Zoom Image',
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',
    rotateRight: 'Rotate Right',
    rotateLeft: 'Rotate Left',
    listLabel: 'Option List'
  },
  filterMatchModeOptions: {
    text: [
      FilterMatchMode.STARTS_WITH,
      FilterMatchMode.CONTAINS,
      FilterMatchMode.NOT_CONTAINS,
      FilterMatchMode.ENDS_WITH,
      FilterMatchMode.EQUALS,
      FilterMatchMode.NOT_EQUALS
    ],
    numeric: [
      FilterMatchMode.EQUALS,
      FilterMatchMode.NOT_EQUALS,
      FilterMatchMode.LESS_THAN,
      FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
      FilterMatchMode.GREATER_THAN,
      FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
    ],
    date: [
      FilterMatchMode.DATE_IS,
      FilterMatchMode.DATE_IS_NOT,
      FilterMatchMode.DATE_BEFORE,
      FilterMatchMode.DATE_AFTER
    ]
  },
  zIndex: {
    modal: 1100,
    overlay: 1000,
    menu: 1000,
    tooltip: 1100
  },
  pt: undefined,
  ptOptions: {
    mergeSections: true,
    mergeProps: false
  },
  unstyled: false,
  csp: {
    nonce: undefined
  }
}

const ZeymalVueSymbol = Symbol()

export function useZeymal() {
  const Zeymal = inject(ZeymalVueSymbol)
  if (!Zeymal) {
    throw new Error('Zeymal is not installed!')
  }
  return Zeymal
}

function switchTheme(currentTheme, newTheme, linkElementId, callback) {
  if (currentTheme !== newTheme) {
    const linkElement = document.getElementById(linkElementId)
    const cloneLinkElement = linkElement.cloneNode(true)
    const newThemeUrl = linkElement.getAttribute('href').replace(currentTheme, newTheme)

    cloneLinkElement.setAttribute('id', linkElementId + '-clone')
    cloneLinkElement.setAttribute('href', newThemeUrl)
    cloneLinkElement.addEventListener('load', () => {
      linkElement.remove()
      cloneLinkElement.setAttribute('id', linkElementId)

      if (callback) {
        callback()
      }
    })

    if (linkElement.parentNode) {
      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling)
    }
  }
}

export default {
  install: async (app, options) => {
    try {
      defaultOptions.locale = await initInstitutionDetail()
      const configOptions = options ? { ...defaultOptions, ...options } : { ...defaultOptions }
      const Zeymal = {
        config: reactive(configOptions),
        changeTheme: switchTheme
      }
      app.config.globalProperties.$primevue = Zeymal
      app.provide(ZeymalVueSymbol, Zeymal)
    } catch (error) {
      console.error('Error during installation primevue config:', error)
    }
  }
}

async function initInstitutionDetail() {
  try {
    const authStore = useAuthenticationStore()
    await authStore.storeGetInstitutionDomain()

    if (authStore.getIsLoadPublicDetail) {
      await authStore.storeInstitutionPublicDetailByURL()
    }

    return authStore.getInstitutionLocaleDetail ?? defaultOptions.locale
  } catch (error) {
    console.error('Error in initInstitutionDetail primevue config', error)
  }
}
