export default [
  {
    path: '/poll',
    name: '',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    children: [
      {
        path: '',
        name: 'poll',
        component: () => import('@/views/collaboration/poll/poll.vue'),
        meta: {
          pageTitle: 'Online poll',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'view-poll',
        name: 'viewPoll',
        component: () => import('@/views/collaboration/poll/view-poll.vue'),
        meta: {
          pageTitle: 'View Poll',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      }
    ]
  }
]
