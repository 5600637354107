export default [
  {
    path: '/student',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            children: [
              {
                path: '',
                name: 'studentDetail',
                component: () => import('@/views/student/student-detail.vue'),
                meta: {
                  pageTitle: 'Student Detail',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'student-preview/:code/:sectionID?/',
                name: 'studentpreview',
                component: () => import('@/views/student/student-preview.vue'),
                meta: {
                  pageTitle: 'Student Preview',
                  requireAuthorization: true,
                  permission: ['admin', 'employee', 'student']
                },
                props: true
              },
              {
                path: 'student-view-profile/:code/:sectionID?/',
                name: 'studentViewProfile',
                component: () => import('@/views/student/student-view-profile.vue'),
                meta: {
                  pageTitle: 'Student View Profile',
                  requireAuthorization: true,
                  permission: ['admin', 'employee', 'student']
                },
                props: true
              }
            ]
          },
          {
            path: 'student-admission',
            component: () => import('@/views/student/student-admission.vue'),
            meta: {
              pageTitle: 'Student Admission',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            },
            children: [
              {
                path: '',
                name: 'studentadmission',
                components: {
                  step: () => import('@/views/student/fragments/steps/personal-details.vue')
                },
                meta: {
                  pageTitle: 'Student Admission - Personal Detail',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'contact',
                name: 'studentcontact',
                components: {
                  step: () => import('@/views/student/fragments/steps/contact-details.vue')
                },
                meta: {
                  pageTitle: 'Student Admission - Contact Detail',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'guardian',
                name: 'guardian',
                components: {
                  step: () => import('@/views/student/fragments/steps/guardian-details.vue')
                },
                meta: {
                  pageTitle: 'Student Admission - Guardian Detail',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'previous-institute',
                name: 'previousinstitute',
                components: {
                  step: () =>
                    import('@/views/student/fragments/steps/previous-institute-details.vue')
                },
                meta: {
                  pageTitle: 'Student Admission - Previous Institute Detail',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'additional-details',
                name: 'additionaldetails',
                components: {
                  step: () => import('@/views/student/fragments/steps/additional-details.vue')
                },
                meta: {
                  pageTitle: 'Student Admission - Additional Detail',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              }
            ]
          },
          {
            path: 'addguardian',
            name: 'addguardian',
            component: () => import('@/views/student/fragments/addguardian.vue'),
            meta: {
              pageTitle: 'Add Student Guardian',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'configuresibling',
            name: 'configuresibling',
            component: () => import('@/views/student/fragments/configuresibling.vue'),
            meta: {
              pageTitle: 'Configure Sibling',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'advancefee',
            name: 'advancefee',
            component: () => import('@/views/student/advancefee.vue'),
            meta: {
              pageTitle: 'Student Advance Fee',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'studentdocumentcategory',
            name: 'studentdocumentcategory',
            component: () => import('@/views/student/documentcategories.vue'),
            meta: {
              pageTitle: 'Student Document Category',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      }
    ]
  }
]
