export default [
  {
    path: 'gradebook-report',
    children: [
      {
        path: '',
        name: 'gradebookReport',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Gradebook Report',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'result-digest',
        name: 'resultDigestReport',
        component: () => import('@/views/report/gradebook-report/result-digest.vue'),
        meta: {
          pageTitle: 'Result Digest',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'student-digest',
        name: 'studentDigestReport',
        component: () => import('@/views/report/gradebook-report/student-digest.vue'),
        meta: {
          pageTitle: 'Student Digest',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      }
    ]
  }
]
