export default [
  {
    path: '/albums',
    name: '',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    children: [
      {
        path: '',
        name: 'album',
        component: () => import('@/views/collaboration/gallery/albums.vue'),
        meta: {
          pageTitle: 'Album',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'category-show',
        name: 'albumImages',
        component: () => import('@/views/collaboration/gallery/album-images.vue'),
        meta: {
          pageTitle: 'Images',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: '',
        name: 'album',
        component: () => import('@/views/collaboration/gallery/albums.vue'),
        meta: {
          pageTitle: 'Album',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      }
    ]
  }
]
