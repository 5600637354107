import reportSettingsRoute from './report-settings.route.js'
export default [
  {
    path: '/configuration',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    meta: {
      pageTitle: 'Configuration',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    },
    children: [
      {
        path: '',
        name: 'configuration',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Configuration',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'system-settings',
        name: 'systemSettings',
        component: () =>
          import('@/views/configuration/system-settings/institution-system-settings.vue'),
        meta: {
          pageTitle: 'System Settings',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'custom-word',
        name: 'customWord',
        component: () => import('@/views/configuration/custom-word/custom-words.vue'),
        meta: {
          pageTitle: 'Custom Word',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'student-category',
        name: 'studentCategory',
        component: () => import('@/views/configuration/student-category/student-category.vue'),
        meta: {
          pageTitle: 'Student Category',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'data-sorting',
        name: 'dataSorting',
        component: () => import('@/views/configuration/default-sorting/default-sorting.vue'),
        meta: {
          pageTitle: 'Data Sorting',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'guardian-relationship',
        name: 'guardianRelationship',
        component: () =>
          import('@/views/configuration/guardian-relationship/guardian-relationship.vue'),
        meta: {
          pageTitle: 'Guardian Relationship',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'blood-group',
        name: 'bloodGroup',
        component: () => import('@/views/configuration/blood-group/blood-group.vue'),
        meta: {
          pageTitle: 'Blood Group',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'document-category',
        name: 'documentCategory',
        component: () => import('@/views/configuration/document-category/document-category.vue'),
        meta: {
          pageTitle: 'Document Category',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'grading-system',
        name: 'gradingSystem',
        component: () => import('@/views/configuration/grading-system/grading-system.vue'),
        meta: {
          pageTitle: 'Grading System',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'featured-access-setting',
        name: 'featuredAccessSetting',
        component: () =>
          import('@/views/configuration/featured-access-setting/featured-access-setting.vue'),
        meta: {
          pageTitle: 'Featured Setting',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'notification-control-setting',
        name: 'notificationControlSetting',
        component: () =>
          import(
            '@/views/configuration/notification-control-setting/notification-control-settings.vue'
          ),
        meta: {
          pageTitle: 'Notification Setting',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      ...reportSettingsRoute,

      {
        path: 'admission-additional-field',
        name: 'admissionAdditionalField',
        component: () =>
          import(
            '@/views/configuration/admission-additional-detail/admission-additional-field.vue'
          ),
        meta: {
          pageTitle: 'Additional Field',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'religion',
        name: 'religion',
        component: () => import('@/views/configuration/religion/religion.vue'),
        meta: {
          pageTitle: 'Religion',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      }
    ]
  }
]
