import programManagementRoute from './program-management.route.js'
import academicTransitionManagementRoute from './academic-transition-management.route.js'
import studentRollNumberRoute from './student-roll-number.route.js'
export default [
  {
    path: '/academic-management',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    meta: {
      pageTitle: 'Academic Management',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    },
    children: [
      {
        path: '',
        name: 'academicManagement',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Academic Management',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      ...programManagementRoute,

      {
        path: 'program-setting',
        name: 'programSetting',
        component: () => import('@/views/academic-management/program-setting/program-setting.vue'),
        meta: {
          pageTitle: 'Program Setting',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'academic-year',
        name: 'academicYear',
        component: () => import('@/views/academic-management/academic-year/academic-year.vue'),
        meta: {
          pageTitle: 'Academic Year',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      ...academicTransitionManagementRoute,

      ...studentRollNumberRoute
    ]
  }
]
