import { createApp, defineAsyncComponent } from 'vue'
import Zeymal from '@/components/zeymal-core-library/config/zeymal.js'
import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import router from './router'
import App from './App.vue'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './assets/custom-css/primevue.css'
import Tooltip from '@/components/zeymal-core-library/tooltip/Tooltip.js'
import ToastService from '@/components/zeymal-core-library/toastservice/ToastService.js'
import BadgeDirective from '@/components/zeymal-core-library/badgedirective/BadgeDirective.js'
import FocusTrap from '@/components/zeymal-core-library/focustrap/FocusTrap.js'
import Ripple from '@/components/zeymal-core-library/ripple/Ripple.js'
import ConfirmationService from '@/components/zeymal-core-library/confirmationservice/ConfirmationService.js'
import generateModuleActionPermission from '@/mixins/mixin-module-action-permission.js'
const applicationInstance = createApp(App)
  .use(createPinia().use(piniaPluginPersistedState))
  .use(Zeymal, {
    ripple: true,
    inputStyle: 'filled',
    inputVariant: 'filled',
    pt: {
      global: {
        css: `
          label {
              font-weight: 500;
              color:#000;
          }
      `
      }
    }
  })
  .use(router)
  .use(ToastService)
  .use(ConfirmationService)
  .directive('tooltip', Tooltip)
  .directive('badge', BadgeDirective)
  .directive('ripple', Ripple)
  .directive('focustrap', FocusTrap)
  .mixin(generateModuleActionPermission)
  .component(
    'ZeymalDataLoader',
    defineAsyncComponent(
      () => import('@/components/custom-data-loader/zeymal-data-loader-normal.vue')
    )
  )
  .component(
    'ZeymalCoreCard',
    defineAsyncComponent(() => import('@/components/custom-card/zeymal-core-card.vue'))
  )
  .component(
    'ZeymalCustomDataTable',
    defineAsyncComponent(
      () => import('@/components/custom-data-table/zeymal-custom-data-table.vue')
    )
  )
  .component(
    'ZeymalCourseDropDown',
    defineAsyncComponent(() => import('@/components/custom-dropdown/zeymal-course-drop-down.vue'))
  )
  .component(
    'ZeymalBreadcrumbs',
    defineAsyncComponent(() => import('@/components/breadcrumbs/zeymal-breadcrumbs.vue'))
  )
  .component(
    'ZeymalCoreDialogModal',
    defineAsyncComponent(
      () => import('@/components/custom-dialog-modal/zeymal-core-dialog-modal.vue')
    )
  )
  .component(
    'Calendar',
    defineAsyncComponent(() => import('@/components/zeymal-core-library/calendar/Calendar.vue'))
  )
  .component(
    'InputText',
    defineAsyncComponent(() => import('@/components/zeymal-core-library/inputtext/InputText.vue'))
  )
  .component(
    'MultiSelect',
    defineAsyncComponent(
      () => import('@/components/zeymal-core-library/multiselect/MultiSelect.vue')
    )
  )
  .component(
    'InputSwitch',
    defineAsyncComponent(
      () => import('@/components/zeymal-core-library/inputswitch/InputSwitch.vue')
    )
  )
  .component(
    'Textarea',
    defineAsyncComponent(() => import('@/components/zeymal-core-library/textarea/Textarea.vue'))
  )
  .component(
    'Dropdown',
    defineAsyncComponent(() => import('@/components/zeymal-core-library/dropdown/Dropdown.vue'))
  )
  .component(
    'Tag',
    defineAsyncComponent(() => import('@/components/zeymal-core-library/tag/Tag.vue'))
  )
  .component(
    'Button',
    defineAsyncComponent(() => import('@/components/zeymal-core-library/button/Button.vue'))
  )
  .component(
    'Checkbox',
    defineAsyncComponent(() => import('@/components/zeymal-core-library/checkbox/Checkbox.vue'))
  )
  .component(
    'RadioButton',
    defineAsyncComponent(
      () => import('@/components/zeymal-core-library/radiobutton/RadioButton.vue')
    )
  )
  .component(
    'FileUpload',
    defineAsyncComponent(() => import('@/components/zeymal-core-library/fileupload/FileUpload.vue'))
  )
  .component(
    'InlineMessage',
    defineAsyncComponent(
      () => import('@/components/zeymal-core-library/inlinemessage/InlineMessage.vue')
    )
  )
  .component(
    'Toast',
    defineAsyncComponent(() => import('@/components/zeymal-core-library/toast/Toast.vue'))
  )
router
  .isReady()
  .then(() => {
    applicationInstance.mount('#app')
  })
  .catch((error) => {
    console.error('Error during router initialization:', error)
  })
