import academicManagementRoute from './academic-management-report.route'
import gradebookRoute from './gradebook-report.route.js'
import attendanceRoute from './attendance-report.route.js'
import financeRoute from './finance-report.route.js'
export default [
  {
    path: '/report',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    meta: {
      requireAuthorization: true,
      permission: ['admin', 'employee']
    },
    children: [
      {
        path: '',
        name: 'report',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Report',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      ...academicManagementRoute,
      {
        path: 'student-report',
        name: 'studentReport',
        component: () => import('@/views/report/student-report.vue'),
        meta: {
          pageTitle: 'Student Report',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'employee-report',
        name: 'employeeReport',
        component: () => import('@/views/report/employee-report.vue'),
        meta: {
          pageTitle: 'Employee Report',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      ...gradebookRoute,
      ...attendanceRoute,
      ...financeRoute
    ]
  }
]
