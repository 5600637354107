export default [
  {
    path: 'gradebook-setting',
    children: [
      {
        path: '',
        name: 'gradebookSetting',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Gradebook Settings',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'grading-profile',
        children: [
          {
            path: '',
            name: 'gradingProfile',
            component: () =>
              import('@/views/gradebook/settings/gradebook-profiles/grading-profile.vue'),
            meta: {
              pageTitle: 'Gradebook Profile',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'evaluation-grade/:gradingProfileId?/',
            name: 'evaluationGrade',
            component: () =>
              import('@/views/gradebook/settings/gradebook-profiles/evaluation-grade.vue'),
            meta: {
              pageTitle: 'Evaluation Grade',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },
      //
      {
        path: 'ranking-profile',
        children: [
          {
            path: '',
            name: 'rankingProfile',
            component: () =>
              import('@/views/gradebook/settings/ranking-profile/ranking-profile.vue'),
            meta: {
              pageTitle: 'Ranking Profile',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'raking-level/:rankingProfileId?/',
            name: 'rankingLevel',
            component: () => import('@/views/gradebook/settings/ranking-profile/ranking-level.vue'),
            meta: {
              pageTitle: 'Ranking Level',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },
      //
      {
        path: 'activity-profile',
        children: [
          {
            path: '',
            name: 'activityProfile',
            component: () =>
              import('@/views/gradebook/settings/activity-profiles/activity-profile.vue'),
            meta: {
              pageTitle: 'Activity Profile',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'activity/:activityProfileId?/',
            name: 'activityProfileActivity',
            component: () =>
              import('@/views/gradebook/settings/activity-profiles/activity-profile-activity.vue'),
            meta: {
              pageTitle: 'Activity',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'activity/:activityProfileId?/competency/:activityId?/',
            name: 'activityProfileCompetency',
            component: () =>
              import(
                '@/views/gradebook/settings/activity-profiles/activity-profile-competency.vue'
              ),
            meta: {
              pageTitle: 'Competency',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'activity/:activityProfileId?/competency/:activityId?/learning-outcome/:competencyId?/',
            name: 'activityProfileLearningOutcome',
            component: () =>
              import(
                '@/views/gradebook/settings/activity-profiles/activity-profile-learning-outcome.vue'
              ),
            meta: {
              pageTitle: 'Learning Outcome',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },
      {
        path: 'attribute-profile',
        children: [
          {
            path: '',
            name: 'attributeProfile',
            component: () =>
              import('@/views/gradebook/settings/attribute-profiles/attribute-profile.vue'),
            meta: {
              pageTitle: 'Attribute Profile',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'evaluation-attribute-profile/:attributeId?/',
            name: 'evaluationAttributeProfile',
            component: () =>
              import(
                '@/views/gradebook/settings/attribute-profiles/evaluation-attribute-profile.vue'
              ),
            meta: {
              pageTitle: 'Evaluation Attribute Profile',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'evaluation-attribute-profile/:attributeId?/evaluation-sub-attribute/:evaluationAttributeID?/',
            name: 'evaluationSubAttribute',
            component: () =>
              import('@/views/gradebook/settings/attribute-profiles/evaluation-sub-attribute.vue'),
            meta: {
              pageTitle: 'Evaluation Sub Attribute',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },
      {
        path: 'remark-bank',
        children: [
          {
            path: '',
            name: 'remarkBank',
            component: () => import('@/views/gradebook/settings/remark-banks/remark-bank.vue'),
            meta: {
              pageTitle: 'Remark Bank',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'remark-bank-field-remark/:remarkBankId?/',
            name: 'remarkBankFieldRemark',
            component: () =>
              import('@/views/gradebook/settings/remark-banks/remark-bank-field-remark.vue'),
            meta: {
              pageTitle: 'Remark Bank Remarks',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      }
    ]
  }
]
