import { defineStore } from 'pinia'
import { useDashboardStore } from '@/stores/dashboard/dashboardStore.js'
import { generateArrayofModuleActionPermission } from '@/_helpers/authentication.utils.js'
import { encodeHexa, decodeHexa } from '@/_helpers/crypto.utils.js'
import cookiesStorage from 'js-cookie'
export const usePermissionStore = defineStore('permissionStore', {
  /************************************************************
                        @State Start
     ************************************************************/
  state: () => ({
    modulePermissionState: []
  }),

  persist: [
    {
      key: '_zyml_mdprms',
      paths: ['modulePermissionState'],
      storage: {
        getItem: (key) => {
          const encodedValue = cookiesStorage.get(key)
          return encodedValue ? JSON.parse(encodedValue) : null
        },
        setItem: (key, value) => {
          cookiesStorage.set(key, JSON.stringify(value), {
            // httpOnly: import.meta.env.VITE_APP_PROD === 'true',
            // secure: import.meta.env.VITE_APP_PROD === 'true',
            httpOnly: false,
            secure: false,
            sameSite: 'Strict',
            path: '/'
          })
        },
        removeItem: (key) => {
          cookiesStorage.remove(key)
        }
      }
    }
  ],

  /************************************************************
                            @Getters Start
    ************************************************************/
  getters: {
    /*-----------------------------------------------------------
                          @Start Load Module Action Permissions
        -----------------------------------------------------------*/
    getListModuleActionPermission(state) {
      return decodeHexa(state.modulePermissionState)
    }
  },

  /************************************************************
                          @Actions Start
    ************************************************************/
  actions: {
    /**********************************************************
              @Start Generate Module Action Permissions
         **********************************************************/

    async storeLoadModuleActionPermission(moduleId) {
      this.modulePermissionState = encodeHexa([])
      const actionState = useDashboardStore().dashboardConfigurationState.actions
      const modules = actionState ? actionState.filter((obj) => obj.ash_ModuleId === moduleId) : []

      const output = modules ? generateArrayofModuleActionPermission(modules) : []
      return (this.modulePermissionState = output ? encodeHexa(output) : [])
    }
  }
})
