import ConfirmationEventBus from '../confirmationeventbus/ConfirmationEventBus.js'
import { ZeymalConfirmSymbol } from '../useconfirm/UseConfirm.js'

export default {
  install: (app) => {
    const ConfirmationService = {
      require: (options) => {
        ConfirmationEventBus.emit('confirm', options)
      },
      close: () => {
        ConfirmationEventBus.emit('close')
      }
    }

    app.config.globalProperties.$confirm = ConfirmationService
    app.provide(ZeymalConfirmSymbol, ConfirmationService)
  }
}
