import gradebookSetting from './gradebook-setting.route.js'

import examPlanner from './exam-planner.route.js'

import manageGradebook from './manage-gradebook.route.js'
export default [
  {
    path: '/gradebook',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    children: [
      {
        path: '',
        name: 'gradebook',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Gradebook',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      ...gradebookSetting,
      ...examPlanner,
      ...manageGradebook
    ]
  }
]
