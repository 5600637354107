export default [
  {
    path: '/custom-import',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    children: [
      {
        path: '',
        name: 'customImport',
        component: () => import('@/views/custom-import/import-model.vue'),
        meta: {
          pageTitle: 'Custom Import',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'generate-student-admission-model/:modelID/:modelCode/:uniquePathCode/',
        name: 'E202C166-2D93-4781-9DCB-8378130354EB',
        component: () =>
          import('@/views/custom-import/student/generate-student-admission-model.vue'),
        meta: {
          pageTitle: 'Generate Student Admission Model',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'student-verify-model/:modelID/:modelCode/:uniquePathCode/',
        name: 'studentAdmissionVerifyModel',
        component: () => import('@/views/custom-import/student/student-admission-verify-model.vue'),
        meta: {
          pageTitle: 'Student Admission Verify Model',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'generate-student-attendance-model/:modelID/:modelCode/:uniquePathCode/',
        name: '9A044F99-E44C-48DE-8C55-0DDDC2230626',
        component: () =>
          import('@/views/custom-import/student/generate-student-attendance-model.vue'),
        meta: {
          pageTitle: 'Generate Student Attendance Model',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'generate-employee-admission-model/:modelID/:modelCode/:uniquePathCode/',
        name: '1C273433-E135-447B-8327-69BFF466E3C9',
        component: () =>
          import('@/views/custom-import/employee/generate-employee-admission-model.vue'),
        meta: {
          pageTitle: 'Generate Employee Admission Model',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'employee-verify-model/:modelID/:modelCode/:uniquePathCode/',
        name: 'employeeAdmissionVerifyModel',
        component: () =>
          import('@/views/custom-import/employee/employee-admission-verify-model.vue'),
        meta: {
          pageTitle: 'Employee Admission Verify Model',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'generate-employee-attendance-model/:modelID/:modelCode/:uniquePathCode/',
        name: 'E43E57D4-D912-4B80-BB8C-58B7AA1CF89F',
        component: () =>
          import('@/views/custom-import/employee/generate-employee-attendance-model.vue'),
        meta: {
          pageTitle: 'Generate Employee Attendance Model',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'upload-image-model/:modelID/:modelCode/:uniquePathCode/',
        name: 'F8FCC4BF-12B9-4E5A-97AA-CA5704E88DB8',
        component: () => import('@/views/custom-import/media/upload-image-model.vue'),
        meta: {
          pageTitle: 'Upload Image Model',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'attendance-verify-model/:modelID/:modelCode/:uniquePathCode/:requestType/',
        name: 'attendanceVerifyModel',
        component: () => import('@/views/custom-import/attendance-verify-model.vue'),
        meta: {
          pageTitle: 'Attendance Verify Model',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      }
    ]
  }
]
