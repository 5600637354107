export default [
  {
    path: '/applicant',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    meta: {
      pageTitle: 'Applicant',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    },
    children: [
      {
        path: '/applicant',
        name: 'applicant',
        component: () => import('@/views/dynamic-card.vue'),

        meta: {
          pageTitle: 'Applicant',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'enquiry',
        children: [
          {
            path: '',
            name: 'enquiry',
            component: () => import('@/views/applicant/enquiry/view-enquiries.vue'),
            meta: {
              pageTitle: 'Enquiry',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'enquiry-details/:id/',
            name: 'enquiryDetails',
            component: () => import('@/views/applicant/enquiry/enquiry-details.vue'),
            meta: {
              pageTitle: 'Enquiry Details',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            },
            props: true
          },
          {
            path: 'edit/:id/',
            name: 'edit',
            component: () => import('@/views/applicant/enquiry/edit-enquiry.vue'),
            meta: {
              pageTitle: 'Edit Enquiry',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            },
            props: true
          },
          {
            path: 'new',
            name: 'newEnquiry',
            component: () => import('@/views/applicant/enquiry/new-enquiry.vue'),
            meta: {
              pageTitle: 'New Enquiry',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'reports',
            children: [
              {
                path: '',

                children: [
                  {
                    path: '',
                    name: 'reportEnquiry',
                    component: () => import('@/views/dynamic-card.vue'),
                    meta: {
                      pageTitle: 'Enquiry Reports',
                      requireAuthorization: true,
                      permission: ['admin', 'employee']
                    }
                  },
                  {
                    path: 'enquiry-reports/:type?/',
                    children: [
                      {
                        path: '',
                        name: 'enquiryReports',
                        component: () =>
                          import('@/views/applicant/enquiry/Reports/all-enquiry-reports.vue'),
                        meta: {
                          pageTitle: 'Enquiry Report Details',
                          requireAuthorization: true,
                          permission: ['admin', 'employee']
                        },
                        props: true
                      },
                      {
                        path: '/enquiry-reports/:action/:type?/:id?/:academicyear/',
                        name: 'enquiryInternalReports',
                        component: () =>
                          import('@/views/applicant/enquiry/Reports/enquiry-internal-reports.vue'),
                        meta: {
                          pageTitle: 'Enquiry Report Details',
                          requireAuthorization: true,
                          permission: ['admin', 'employee']
                        },
                        props: true
                      }
                    ]
                  },
                  {
                    path: 'enquiry-summary-reports',
                    children: [
                      {
                        path: '',
                        name: 'enquirySummaryReports',
                        component: () =>
                          import('@/views/applicant/enquiry/Reports/enquiry-summary-reports.vue'),
                        meta: {
                          pageTitle: 'Enquiry Summary Report ',
                          requireAuthorization: true,
                          permission: ['admin', 'employee']
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: 'settings',
            children: [
              {
                path: '',
                name: 'settings',
                component: () => import('@/views/dynamic-card.vue'),
                meta: {
                  pageTitle: 'Enquiry Settings',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'form',
                name: 'form',
                component: () => import('@/views/applicant/enquiry/form-settings.vue'),
                meta: {
                  pageTitle: 'Enquiry Form settings',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'enquiry-settings',
                name: 'enquirySettings',
                component: () => import('@/views/applicant/enquiry/enquiry-settings.vue'),
                meta: {
                  pageTitle: 'Enquiry Settings',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'active-year-and-course',
                name: 'activeYearAndCourse',
                component: () =>
                  import('@/views/applicant/enquiry/active-year-course-settings.vue'),
                meta: {
                  pageTitle: 'Enquiry Academic Year & Course Settings',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/enquiry-form',
    name: 'embedEnquiryForm',
    requireAuthorization: false,
    component: () => import('@/views/applicant/enquiry/embed-enquiry-form.vue')
  }
]
