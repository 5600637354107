export default [
  {
    path: '/module',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    children: [
      {
        path: '',
        name: 'module',
        component: () => import('@/views/permission/module.vue'),
        meta: {
          pageTitle: 'Module',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: '/module/module-permission',
        name: 'modulePermission',
        component: () => import('@/views/permission/module-permission.vue'),
        meta: {
          pageTitle: 'Module Permission',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      }
    ]
  }
]
