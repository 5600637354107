export default [
  {
    path: 'manage-gradebook',
    name: 'manageGradebook',
    component: () => import('@/views/gradebook/gradebook-management/manage-gradebook.vue'),
    meta: {
      pageTitle: 'Manage Gradebook',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'manage-gradebook/manage-exam/:levelID/:academicYearID/',
    name: 'manageExam',
    component: () =>
      import('@/views/gradebook/gradebook-management/exam-management/manage-exam.vue'),
    meta: {
      pageTitle: 'Manage Exam',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'manage-gradebook/manage-exam/:levelID/:academicYearID/batch-preview-report/:examID/',
    name: 'batchPreviewReport',
    component: () =>
      import('@/views/gradebook/gradebook-management/report-management/batch-preview-report.vue'),
    meta: {
      pageTitle: 'Batch Preview Report',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },
  {
    path: 'manage-gradebook/manage-exam/:levelID/:academicYearID/batch-preview-report/:examID/scheduled-batch-result-sheet/:scheduledBatchID/',
    name: 'scheduledBatchResultSheet',
    component: () =>
      import(
        '@/views/gradebook/gradebook-management/report-management/scheduled-batch-result-sheet.vue'
      ),
    meta: {
      pageTitle: 'Scheduled Batch Result Sheet',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },
  {
    path: 'manage-gradebook/manage-exam/:levelID/:academicYearID/batch-preview-report/:examID/student-overall-result-sheet/:scheduledBatchID/',
    name: 'studentOverallResultSheet',
    component: () => import('@/views/gradebook/gradebook-report/student-overall-result-sheet.vue'),
    meta: {
      pageTitle: 'Student Overall Result Sheet',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },
  {
    path: 'manage-gradebook/manage-exam/:levelID/:academicYearID/batch-preview-report/:examID/overall-batch-result-sheet/:scheduledBatchID/',
    name: 'overallBatchResultSheet',
    component: () =>
      import(
        '@/views/gradebook/gradebook-management/report-management/overall-batch-result-sheet.vue'
      ),
    meta: {
      pageTitle: 'Overall Result Sheet',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },
  {
    path: 'manage-gradebook/manage-exam/:levelID/:academicYearID/exam-report/report-preview/',
    name: 'manageExamReportPreview',
    component: () =>
      import('@/views/gradebook/gradebook-management/report-management/report-preview.vue'),
    meta: {
      pageTitle: 'Manage Exam Report Preview',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'manage-gradebook/manage-exam/:levelID/:academicYearID/exam-detail/:examID/',
    name: 'manageExamDetail',
    component: () =>
      import('@/views/gradebook/gradebook-management/exam-management/exam-detail.vue'),
    meta: {
      pageTitle: 'Exam Detail',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },
  {
    path: 'manage-gradebook/manage-exam/exam-timetable',
    name: 'examTimetable',
    component: () =>
      import('@/views/gradebook/gradebook-management/exam-management/exam-timetable.vue'),
    meta: {
      pageTitle: 'Exam Timetable',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'manage-gradebook/manage-exam/:levelID/:academicYearID/exam-detail/:examID/subject-exam-bulk-marks-import/:scheduledExamID/',
    name: 'manageSubjectExamBulkMarksImport',
    component: () =>
      import(
        '@/views/gradebook/gradebook-management/exam-management/fragments/import-export/subject-exam-bulk-marks-import.vue'
      ),
    meta: {
      pageTitle: 'Subject Exam Bulk Marks Import',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },
  {
    path: 'manage-gradebook/manage-exam/:levelID/:academicYearID/exam-detail/:examID/skill-or-attribute-exam-bulk-marks-import/:scheduledExamID/',
    name: 'manageSkillOrAttributeExamBulkMarksImport',
    component: () =>
      import(
        '@/views/gradebook/gradebook-management/exam-management/fragments/import-export/skill-or-attribute-exam-bulk-marks-import.vue'
      ),
    meta: {
      pageTitle: 'Skill or Attribute Exam Bulk Marks Import',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },
  {
    path: 'manage-gradebook/manage-exam/:levelID/:academicYearID/exam-detail/:examID/exam-marks-and-remarks/:scheduledExamID?/',
    name: 'examMarksAndRemarks',
    component: () =>
      import(
        '@/views/gradebook/gradebook-management/exam-management/fragments/marks-remarks/exam-marks-and-remarks.vue'
      ),
    meta: {
      pageTitle: 'Exam Marks & Remark',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'manage-gradebook/manage-exam/:levelID/:academicYearID/exam-detail/:examID/exam-result/:examSubjectID?/',
    name: 'examResult',
    component: () =>
      import(
        '@/views/gradebook/gradebook-management/exam-management/fragments/result/exam-result.vue'
      ),
    meta: {
      pageTitle: 'Exam Result',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },
  /***************************************
   * @Start Students Activity Mark Entry
   **************************************/
  {
    path: 'manage-gradebook/manage-exam/:levelID/:academicYearID/exam-detail/:examID/students-activity-mark-entry/:scheduledID/',
    name: 'studentsActivityMarkEntry',
    component: () =>
      import(
        '@/views/gradebook/gradebook-management/exam-management/fragments/marks-remarks/activities-mark-entry-table.vue'
      ),
    meta: {
      pageTitle: 'Students Activity Mark Entry',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },
  /*******************************
   * @Start Schedule Exam
   *******************************/

  {
    path: 'manage-gradebook/manage-exam/:levelID/:academicYearID/exam-detail/:examID/schedule-subject-exam-initial-step/',
    name: 'scheduleSubjectExamInitialStep',
    component: () =>
      import(
        '@/views/gradebook/gradebook-management/exam-management/fragments/exam-plan/schedule-subject-exam-initial-step.vue'
      ),
    meta: {
      pageTitle: 'Schedule Subject Exam Initial Step',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'manage-gradebook/manage-exam/:levelID/:academicYearID/exam-detail/:examID/schedule-subject-exam-final-step/',
    name: 'scheduleSubjectExamFinalStep',
    component: () =>
      import(
        '@/views/gradebook/gradebook-management/exam-management/fragments/exam-plan/schedule-subject-exam-final-step.vue'
      ),
    meta: {
      pageTitle: 'Schedule Subject Exam Final Step',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'manage-gradebook/manage-exam/:levelID/:academicYearID/exam-detail/:examID/edit-scheduled-exam/:scheduledID/:sectionID/',
    name: 'editScheduledExam',
    component: () =>
      import(
        '@/views/gradebook/gradebook-management/exam-management/fragments/exam-plan/edit-scheduled-exam.vue'
      ),
    meta: {
      pageTitle: 'Edit Scheduled Exam',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  }
]
