import { defineStore } from 'pinia'
import { dashboardEndPoints } from '@/_services/dashboard/dashboard.service.js'
import cookiesStorage from 'js-cookie'
import { encodeHexa, decodeHexa } from '@/_helpers/crypto.utils.js'
import { removeCookies } from '@/_helpers/authentication.utils.js'
export const useDashboardStore = defineStore('dashboardStore', {
  /************************************************************
                      @State Start
   ************************************************************/
  state: () => ({
    dashboardConfigurationState: [],
    /*---------------------------------------
        @Start State for Navigation
    ----------------------------------------*/
    dashboardNavigationState: [],
    /*---------------------------------------
      @Start State for Search Dashboard Modules
   ----------------------------------------*/
    dashboardSearchModuleState: [],

    toggleSideBarState: true,

    activeOverlayState: false,
    /*---------------------------------------
        @Start State for User
    ----------------------------------------*/
    authorizedUserDetailState: {},
    /*---------------------------------------
        @Start State for Program Management
    ----------------------------------------*/
    programStatusState: '66616l7365',
    singularCustomWordsState: [],
    pluralCustomWordsState: [],
    /*-------------------------------------------------------------------
      @Start State to stop double API call to Load Default Configuration
   ----------------------------------------------------------------------*/
    isLoadDefaultConfigurationState: '66616l7365',
    /*---------------------------------------
       @Start State for Template Report
   ----------------------------------------*/
    defaultTemplateState: '66616l7365',
    reportTemplateState: []
  }),

  persist: [
    /*---------------------------------------
        @Start Persist for User Details
    ----------------------------------------*/
    {
      key: '_zyml_usrdt',
      paths: ['authorizedUserDetailState'],
      storage: localStorage
      // storage: {
      //   getItem: (key) => {
      //     const encodedValue = cookiesStorage.get(key, {
      //       secure: import.meta.env.VITE_APP_PROD === 'true'
      //     })
      //     return encodedValue ? JSON.parse(encodedValue) : null
      //   },
      //   setItem: (key, value) => {
      //     cookiesStorage.set(key, JSON.stringify(value), {
      //       secure: import.meta.env.VITE_APP_PROD === 'true'
      //     })
      //   },
      //   removeItem: (key) => {
      //     cookiesStorage.remove(key, {
      //       secure: import.meta.env.VITE_APP_PROD === 'true'
      //     })
      //   }
      // }
    },
    /*---------------------------------------
       @Start Persist for Program Management
    ----------------------------------------*/
    {
      key: '_zyml_prgsts',
      paths: ['programStatusState'],
      storage: localStorage
      // storage: {
      //   getItem: (key) => {
      //     const encodedValue = cookiesStorage.get(key, {
      //       secure: import.meta.env.VITE_APP_PROD === 'true'
      //     })
      //     return encodedValue ? JSON.parse(encodedValue) : null
      //   },
      //   setItem: (key, value) => {
      //     cookiesStorage.set(key, JSON.stringify(value), {
      //       secure: import.meta.env.VITE_APP_PROD === 'true'
      //     })
      //   },
      //   removeItem: (key) => {
      //     cookiesStorage.remove(key, {
      //       secure: import.meta.env.VITE_APP_PROD === 'true'
      //     })
      //   }
      // }
    },
    {
      key: '_zyml_prgsngl',
      paths: ['singularCustomWordsState'],
      storage: localStorage
    },
    {
      key: '_zyml_prgplur',
      paths: ['pluralCustomWordsState'],
      storage: localStorage
    },
    /*---------------------------------------
       @Start Persist for Navbar or Nav Menu
   ----------------------------------------*/
    {
      key: '_zyml_navmnu',
      paths: ['dashboardNavigationState'],
      storage: localStorage
    },
    /*---------------------------------------
       @Start Persist for Dashboard
   ----------------------------------------*/
    {
      key: '_zyml_dshbrd',
      paths: ['dashboardConfigurationState'],
      storage: localStorage
    },
    /*-------------------------------------------------------------------
       @Start State to stop double API call to Load Default Configuration
    ----------------------------------------------------------------------*/
    {
      key: '_zyml_islddfcnf',
      paths: ['isLoadDefaultConfigurationState'],
      storage: localStorage
      // storage: {
      //   getItem: (key) => {
      //     const encodedValue = cookiesStorage.get(key, {
      //       secure: import.meta.env.VITE_APP_PROD === 'true'
      //     })
      //     return encodedValue ? JSON.parse(encodedValue) : null
      //   },
      //   setItem: (key, value) => {
      //     cookiesStorage.set(key, JSON.stringify(value), {
      //       secure: import.meta.env.VITE_APP_PROD === 'true'
      //     })
      //   },
      //   removeItem: (key) => {
      //     cookiesStorage.remove(key, {
      //       secure: import.meta.env.VITE_APP_PROD === 'true'
      //     })
      //   }
      // }
    },
    /*-------------------------------------------------------------------
      @Start State for default template status
   ----------------------------------------------------------------------*/
    {
      key: '_zyml_dftmsts',
      paths: ['defaultTemplateState'],
      storage: localStorage
      // storage: {
      //   getItem: (key) => {
      //     const encodedValue = cookiesStorage.get(key, {
      //       secure: import.meta.env.VITE_APP_PROD === 'true'
      //     })
      //     return encodedValue ? JSON.parse(encodedValue) : null
      //   },
      //   setItem: (key, value) => {
      //     cookiesStorage.set(key, JSON.stringify(value), {
      //       secure: import.meta.env.VITE_APP_PROD === 'true'
      //     })
      //   },
      //   removeItem: (key) => {
      //     cookiesStorage.remove(key, {
      //       secure: import.meta.env.VITE_APP_PROD === 'true'
      //     })
      //   }
      // }
    },
    {
      key: '_zyml_rptmp',
      paths: ['reportTemplateState'],
      storage: localStorage
      // storage: {
      //   getItem: (key) => {
      //     const encodedValue = cookiesStorage.get(key, {
      //       secure: import.meta.env.VITE_APP_PROD === 'true'
      //     })
      //     return encodedValue ? JSON.parse(encodedValue) : null
      //   },
      //   setItem: (key, value) => {
      //     cookiesStorage.set(key, JSON.stringify(value), {
      //       secure: import.meta.env.VITE_APP_PROD === 'true'
      //     })
      //   },
      //   removeItem: (key) => {
      //     cookiesStorage.remove(key, {
      //       secure: import.meta.env.VITE_APP_PROD === 'true'
      //     })
      //   }
      // }
    }
  ],

  /************************************************************
                          @Getters Start
  ************************************************************/
  getters: {
    /*---------------------------------------------------------------------------
      @Start DecodedHexa Getters for Program Management Whether Program is ON/OFF
    ----------------------------------------------------------------------------*/
    getProgramStatus(state) {
      return decodeHexa(state.programStatusState)
    },

    getSingularCustomWords(state) {
      return state.singularCustomWordsState
    },

    getPluralCustomWords(state) {
      return state.pluralCustomWordsState
    },
    /*-----------------------------------------------------------
          @Start Load DecodedHexa User Detail
    -----------------------------------------------------------*/
    getAuthorizedUserDetail(state) {
      return state.authorizedUserDetailState
    },
    /*-----------------------------------------------------------
          @Start Load User Modules
    -----------------------------------------------------------*/
    getListUserModules(state) {
      return state.dashboardConfigurationState.modules
    },
    /*-----------------------------------------------------------
         @Start Load User Modules
   -----------------------------------------------------------*/
    getListProgramSetting(state) {
      return state.dashboardConfigurationState
    },
    /*-----------------------------------------------------------
         @Start Load Default Academic Year
   -----------------------------------------------------------*/
    listAllUserAcademicYearDetail(state) {
      return state.dashboardConfigurationState.academicyear
    },
    /*-----------------------------------------------------------
         @Start Load Default Financial Year
   -----------------------------------------------------------*/
    listAllUserFinancialYearDetail(state) {
      return state.dashboardConfigurationState.financialyear
    },
    /*-----------------------------------------------------------
        @Start Load Dashboard Navigation
    -----------------------------------------------------------*/
    getListDashboardNavigation(state) {
      return state.dashboardNavigationState
    },
    /*-----------------------------------------------------------
          @Start Search Dashboard Modules
      -----------------------------------------------------------*/
    getListDashboardSearchModule(state) {
      return state.dashboardSearchModuleState
    },
    getToggleBarStatus(state) {
      return state.toggleSideBarState
    },
    getActiveOverlayStatus(state) {
      return state.activeOverlayState
    },
    /*--------------------------------------------------------------------------------
       @Start DecodedHexa State to stop double API call to Load Default Configuration
    ---------------------------------------------------------------------------------*/
    getIsLoadDefaultConfiguration(state) {
      return decodeHexa(state.isLoadDefaultConfigurationState)
    },
    /*----------------------------------------------------
      @Start DecodedHexa State of default template status
   --------------------------------------------------------*/
    getIsDefaultTemplate(state) {
      return decodeHexa(state.defaultTemplateState)
    },

    getListReportTemplate(state) {
      return state.reportTemplateState
    }
  },

  /************************************************************
                        @Actions Start
        ************************************************************/
  actions: {
    async storeLoadListDefaultConfiguration() {
      try {
        const { isResult = false, data = [] } =
          await dashboardEndPoints.getAllDefaultConfiguration()
        if (isResult && data) {
          await this.storeGenerateCustomWords(data)
          this.dashboardConfigurationState = data
          this.dashboardNavigationState = data.navbar
          this.authorizedUserDetailState = data.userdetails
          return { isResult, data }
        } else return { isResult, data }
      } catch (error) {
        console.error('An error occurred in storeLoadListDefaultConfiguration:', error)
        await removeCookies(500)
        throw error
      }
    },

    async storeGenerateCustomWords(payload) {
      try {
        this.singularCustomWordsState = []
        this.pluralCustomWordsState = []
        const { customwords = [], coursesettings = {}, institutetemplates = {} } = payload
        this.programStatusState = encodeHexa(coursesettings.ash_Value)
        this.reportTemplateState = institutetemplates.templates
        this.defaultTemplateState = encodeHexa(institutetemplates.ash_IsDefault)

        customwords.forEach((item) => {
          if (!this.singularCustomWordsState.includes(item.ash_LowerCase, item.ash_LowerCasePlural))
            this.singularCustomWordsState.push(item.ash_LowerCase)
          if (!this.pluralCustomWordsState.includes(item.ash_LowerCasePlural, item.ash_LowerCase))
            this.pluralCustomWordsState.push(item.ash_LowerCasePlural)
        })
      } catch (error) {
        console.error('Error in storeGenerateCustomWords:', error)
        throw error
      }
    },

    async mutateDefaultConfigurationState(payload, isUpdate) {
      try {
        payload.forEach((updateItem) => {
          const itemIndex = this.dashboardConfigurationState.customwords.findIndex(
            (item) => item.ash_Id === parseInt(updateItem.ash_Id)
          )

          if (itemIndex !== -1) {
            const { ash_LowerCase, ash_LowerCasePlural, ash_Name } = updateItem
            const itemToUpdate = this.dashboardConfigurationState.customwords[itemIndex]

            itemToUpdate.ash_LowerCase = isUpdate ? ash_LowerCase : ash_Name
            itemToUpdate.ash_LowerCasePlural = isUpdate ? ash_LowerCasePlural : ash_Name
          }
        })
        await this.storeGenerateCustomWords(this.dashboardConfigurationState)
      } catch (error) {
        console.error('Error in mutateDefaultConfigurationState:', error)
      }
    },

    /**********************************************************
                      @Start Program Status True || False
     **********************************************************/
    async storeUpdateProgramStatus(payload) {
      try {
        return await dashboardEndPoints.updateProgramStatus(payload)
      } catch (error) {
        console.error('Failed to update program status:', error)
      }
    },
    /************************************************************
                        @Actions Start for Navigation
    ************************************************************/

    storeLoadNavActive(payload) {
      if (!payload.ash_ISExpanded) {
        const resetAshISExpanded = (array) => {
          const stack = [...array]

          while (stack.length > 0) {
            const item = stack.pop()

            item.ash_ISExpanded = false

            if (item.userS_MODULES) stack.push(...item.userS_MODULES)

            if (item.inverseAsh_Parent) stack.push(...item.inverseAsh_Parent)
          }
        }

        resetAshISExpanded(this.dashboardNavigationState)
      }

      payload.ash_ISExpanded = !payload.ash_ISExpanded

      return
    },

    storeLoadActiveRoute(payload) {
      this.dashboardNavigationState.filter((menuItem) => {
        if (menuItem !== payload) menuItem.ash_ISExpanded = false
        if (menuItem.userS_MODULES && menuItem.userS_MODULES.includes(payload))
          menuItem.ash_ISExpanded = true
        if (menuItem.userS_MODULES) {
          menuItem.userS_MODULES.filter((submenuItems) => {
            if (
              submenuItems.inverseAsh_Parent &&
              submenuItems.inverseAsh_Parent.includes(payload)
            ) {
              menuItem.ash_ISExpanded = true
              submenuItems.ash_ISExpanded = true
            }
          })
        }
      })
      return
    },

    /************************************************************
        @Actions Start for Search
    ************************************************************/

    storeLoadSearchedModule(searchModule) {
      let items = []
      var searchval = searchModule.toLowerCase()
      this.dashboardNavigationState.filter((menuItems) => {
        if (menuItems.ash_DisplayName) {
          if (
            menuItems.ash_DisplayName.toLowerCase().includes(searchval) &&
            menuItems.ash_PathName
          ) {
            items.push(menuItems)
          }
          if (!menuItems.userS_MODULES) return false
          menuItems.userS_MODULES.filter((subItems) => {
            if (
              subItems.ash_DisplayName.toLowerCase().includes(searchval) &&
              subItems.ash_PathName
            ) {
              items.push(subItems)
            }
          })
          this.dashboardSearchModuleState = items
        }
      })
    },

    storeToggleSideBar() {
      this.toggleSideBarState = !this.toggleSideBarState
      this.activeOverlayState = window.innerWidth < 991 ? true : false
      this.toggleSideBarState
    },

    storeUpdateActiveOverlay(payload) {
      this.activeOverlayState = payload
    },

    storeUpdateToggleSideBar(payload) {
      this.toggleSideBarState = payload
    },

    storeUpdateResizeToggle() {
      if (window.innerWidth < 1199) {
        this.toggleSideBarState = false
        this.activeOverlayState = true
      } else {
        this.toggleSideBarState = true
        this.activeOverlayState = false
      }
    },
    /**********************************************************************
       @Actions Start to stop double API call to Load Default Configuration
   ***********************************************************************/

    storeIsLoadDefaultConfiguration(payload) {
      this.isLoadDefaultConfigurationState = encodeHexa(payload)
    },
    /*------------------------------------------
        @Start Custom Program Settings Dropdown
     ------------------------------------------*/

    async storeUpdateCustomProgramDropdown(payload) {
      const { isResult = false } = await dashboardEndPoints.updateCustomProgramDropdown(payload)
      if (isResult) await this.mutateCustomProgramDropdownState(payload)
      return isResult
    },

    async mutateCustomProgramDropdownState(payload) {
      try {
        return this.dashboardConfigurationState?.programstructure.forEach((item) => {
          item.ash_IsActive = item.ash_Id === payload.ash_Id
        })
      } catch (error) {
        console.error('Error in mutateCustomProgramDropdownState:', error)
      }
    },
    /*------------------------------------------
        @Start Mutate Default Academic Year
     ------------------------------------------*/

    mutateDefaultAcademicYearState(payload) {
      try {
        return (this.dashboardConfigurationState.academicyear = payload)
      } catch (error) {
        console.error('Error in mutateDefaultAcademicYearState:', error)
      }
    },
    /*------------------------------------------
            @Start Mutate Default Financial Year
         ------------------------------------------*/
    async mutateDefaultFinancialYearState(payload) {
      try {
        return (this.dashboardConfigurationState.financialyear = payload)
      } catch (error) {
        console.error('Error in mutateDefaultFinancialYearState:', error)
      }
    },

    async mutateAuthorizedUserDetail(payload) {
      if (this.authorizedUserDetailState.ash_Code === payload.ash_Code) {
        this.authorizedUserDetailState.ash_Name = payload.ash_Name
        this.authorizedUserDetailState.ash_Photo = payload.ash_Photo
        this.authorizedUserDetailState.ash_UserIdentifictaion = payload.ash_UserIdentifictaion
      }
      return
    },

    mutateDefaultTemplateStatus(payload) {
      return (this.defaultTemplateState = encodeHexa(payload))
    }
  }
})
