import { JSON_REQUEST } from '@/_helpers/connection'

/************************************************************
  @Start Institution Public Details e.g., Logo, Banner, etc!
************************************************************/

async function getInstitutionPublicDetailByURL(queryParam) {
  return await JSON_REQUEST(queryParam).get('Authy/LoadPublicDetails')
}

/************************************************************
        @Start Authentication
************************************************************/

async function getVerifyUser(queryParam) {
  return await JSON_REQUEST(queryParam).post('Authy/Login')
}

async function getVerifyOTP(queryParam) {
  return await JSON_REQUEST(queryParam).post('Authy/Verify')
}

async function logoutDevice(queryParam) {
  return await JSON_REQUEST(queryParam).put('Authy/NormalDeviceLogout')
}

export const authenticationEndPoints = {
  /************************************************************
  @Start Institution Public Details e.g., Logo, Banner, etc!
 ************************************************************/

  getInstitutionPublicDetailByURL,
  /************************************************************
        @Start Authentication
 ************************************************************/

  getVerifyUser,

  getVerifyOTP,

  logoutDevice
}
