export default [
  {
    path: '/discussion',
    name: '',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    children: [
      {
        path: '',
        name: 'discussion',
        component: () => import('@/views/collaboration/discussion/all-discussion.vue'),
        meta: {
          pageTitle: 'Discussion',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'discussion-group',
        name: 'discussionGroup',
        component: () => import('@/views/collaboration/discussion/discussion-group.vue'),
        meta: {
          pageTitle: 'Discussion Group',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'view-post',
        name: 'viewPost',
        component: () => import('@/views/collaboration/discussion/view-post.vue'),
        meta: {
          pageTitle: 'View Post',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'see-all-members',
        name: 'seeAllMembers',
        component: () => import('@/views/collaboration/discussion/all-members.vue'),
        meta: {
          pageTitle: 'See all members',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      }
    ]
  }
]
