export default [
  {
    path: 'exam-planner',
    name: 'examPlanner',
    component: () => import('@/views/gradebook/exam-planner/exam-planner.vue'),
    meta: {
      pageTitle: 'Exam Planner',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'exam-planner/assessment-plan/:plannerID?/',
    name: 'assessmentPlan',
    component: () => import('@/views/gradebook/exam-planner/assessment-plan/assessment-plan.vue'),
    meta: {
      pageTitle: 'Assessment Plan',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'exam-planner/assessment-plan/:plannerID?/assessment-exam/:assessmentPlanTermID?/',
    name: 'assessmentTypeExam',
    component: () => import('@/views/gradebook/exam-planner/create-exams/assessment-type-exam.vue'),
    meta: {
      pageTitle: 'Assessment Exam',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'exam-planner/assessment-plan/:plannerID?/term-exam/:assessmentPlanTermID?/',
    name: 'termTypeExam',
    component: () => import('@/views/gradebook/exam-planner/create-exams/term-type-exam.vue'),
    meta: {
      pageTitle: 'Term Exam',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'exam-planner/assessment-plan/:plannerID?/planner-exam',
    name: 'plannerTypeExam',
    component: () => import('@/views/gradebook/exam-planner/create-exams/planner-type-exam.vue'),
    meta: {
      pageTitle: 'Planner Exam',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  }
]
