export default [
  {
    path: '/error',
    children: [
      {
        path: '400',
        name: 'error400',
        component: () => import('@/errors/error_400.vue'),
        meta: {
          pageTitle: 'Bad Request',
          requireAuthorization: true
        }
      },

      {
        path: '401',
        name: 'error401',
        component: () => import('@/errors/error_401.vue'),
        meta: {
          pageTitle: 'Forbidden',
          requireAuthorization: true
        }
      },

      {
        path: '403',
        name: 'error403',
        component: () => import('@/errors/error_403.vue'),
        meta: {
          pageTitle: 'Forbidden',
          requireAuthorization: true
        }
      },

      {
        path: '404',
        name: 'error404',
        component: () => import('@/errors/error_404.vue'),
        meta: {
          pageTitle: 'Page Not Found',
          requireAuthorization: true
        }
      },

      {
        path: '500',
        name: 'error500',
        component: () => import('@/errors/error_500.vue'),
        meta: {
          pageTitle: 'Internal Server Error',
          requireAuthorization: true
        }
      }
    ]
  }
]
