import axios from 'axios'
import qs from 'qs'
import { createToaster } from '@meforma/vue-toaster'
import { initServerDate } from '@/_helpers/data.utils.js'
import { removeCookies, checkAuthentication } from '@/_helpers/authentication.utils.js'
const toaster = createToaster({ position: 'bottom-right' })
const connectionOptions = {
  baseURL: import.meta.env.VITE_APP_HOST_ADDRESS,
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { indices: false }),
    encode: (params) => qs.parse(params)
  }
}
const carboneReportConnectionOptions = {
  baseURL: import.meta.env.VITE_APP_CARBONE_HOST_ADDRESS,
  paramsSerializer: {
    encode: qs.parse,
    serialize: qs.stringify
  }
}
export function JSON_REQUEST(params = {}, isCarboneReport = false) {
  const config = isCarboneReport ? carboneReportConnectionOptions : connectionOptions
  const jsonInstance = axios.create({
    ...config,
    params,
    headers: { 'Content-type': 'application/json' }
  })
  instanceInterceptor(jsonInstance, 'json')
  return jsonInstance
}
export function MULTIPART_REQUEST(params = {}) {
  const multipartInstance = axios.create({
    ...connectionOptions,
    params,
    headers: { 'Content-type': 'multipart/form-data' }
  })
  instanceInterceptor(multipartInstance, 'multi')
  return multipartInstance
}
function instanceInterceptor(interceptorType, requestType) {
  interceptorType.interceptors.request.use(
    (config) => {
      checkAuthentication()
      if (config.data) initServerDate(config.data)
      else if (!config.data && config.params) initServerDate(config.params)
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  interceptorType.interceptors.response.use(
    function (response) {
      const result = response.data
      if (import.meta.env.VITE_APP_LOGGER === 'true') console.log(result)
      if (requestType === 'json') {
        if (result.isResult && result.message && response.config.method != 'get')
          toaster.success(result.message)
      } else if (requestType === 'multi') {
        if (result.isResult && result.message) toaster.success(result.message)
      }
      return response.data
    },
    async function (error) {
      if (import.meta.env.VITE_APP_LOGGER === 'true') console.log(error)
      const _status = error.response ? error.response.status : error.code
      const _payload = error.response?.data?.data
      const _message = error.response
        ? error.response.data.message
          ? error.response.data.message
          : error.response.data.Message
            ? error.response.data.Message
            : error.message
        : error.message
      await toastMessage(_status, _message)
      return {
        isResult: false,
        message: _message,
        status: _status,
        data: _payload
      }
    }
  )
}
async function toastMessage(response, message) {
  switch (response) {
    case 400:
    case 403:
      return toaster.warning(message)
    case 401:
      toaster.warning(message)
      return await removeCookies(null, response)
    case 404:
      return toaster.warning(message)
    case 500:
      return toaster.error(message)
    default:
      return toaster.error(message)
  }
}
