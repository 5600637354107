import BaseStyle from '../base/BaseStyle.js'

const classes = {
  root: 'p-badge p-component'
}

export default BaseStyle.extend({
  name: 'badge',
  classes
})
