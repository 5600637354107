import financeSummary from './finance-summary-route.js'
import financeChartofAccount from './finance-chart-of-account-route.js'
export default [
  {
    path: '/finance',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    children: [
      {
        path: '',
        name: 'financeManagement',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Finance Management',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'finance-main',
        name: 'financeMain',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Finance Main',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'fees',
        children: [
          {
            path: '',
            name: 'fees',
            component: () => import('@/views/dynamic-card.vue'),
            meta: {
              pageTitle: 'Fees',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'master-fees',
            name: 'masterFees',
            component: () => import('@/views/finance/fees/finance-master-fees.vue'),
            meta: {
              pageTitle: 'Master Fee',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'create-fee-category',
            children: [
              {
                path: '',
                name: 'createFeeCategory',
                component: () => import('@/views/finance/fees/create-fees/create-fee-category.vue'),
                meta: {
                  pageTitle: 'Create Fee Category',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'create-discount',
                name: 'createFeeDiscount',
                component: () => import('@/views/finance/fees/create-fees/create-fee-discount.vue'),
                meta: {
                  pageTitle: 'Create Fee',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'create-discount/:batchName?/:discountId?/:categoryId?/',
                name: 'createFeeDiscountInCategory',
                props: true,
                component: () => import('@/views/finance/fees/create-fees/create-fee-discount.vue'),
                meta: {
                  pageTitle: 'Create Fee',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'generate-fine',
                children: [
                  {
                    path: '',
                    name: 'generateFine',
                    component: () => import('@/views/finance/fees/create-fees/generate-fine.vue'),
                    meta: {
                      pageTitle: 'Generate Fine',
                      requireAuthorization: true,
                      permission: ['admin', 'employee']
                    }
                  },
                  {
                    name: 'fineDetails',
                    path: 'fine-details/:fineId/',
                    props: true,
                    component: () => import('@/views/finance/fees/create-fees/fine-details.vue'),
                    meta: {
                      pageTitle: 'Generate Fine details',
                      requireAuthorization: true,
                      permission: ['admin', 'employee']
                    }
                  }
                ]
              },
              {
                path: 'fee-management/:categoryId?/',
                children: [
                  {
                    path: '',
                    children: [
                      {
                        path: '',
                        name: 'feeManagement',
                        props: true,
                        component: () =>
                          import('@/views/finance/fees/create-fees/fee-management.vue'),
                        meta: {
                          pageTitle: 'Fee Management',
                          requireAuthorization: true,
                          permission: ['admin', 'employee']
                        }
                      },
                      {
                        path: 'view-particular/:particularsId?/:batchName?/',
                        name: 'viewParticulars',
                        props: true,
                        component: () =>
                          import('@/views/finance/fees/create-fees/view-particular.vue'),
                        meta: {
                          pageTitle: 'View particulars',
                          requireAuthorization: true,
                          permission: ['admin', 'employee']
                        }
                      },
                      {
                        path: 'view-discount/:discountId?/:batchName?/',
                        name: 'viewDiscount',
                        props: true,
                        component: () =>
                          import('@/views/finance/fees/create-fees/view-discount.vue'),
                        meta: {
                          pageTitle: 'View discount',
                          requireAuthorization: true,
                          permission: ['admin', 'employee']
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: 'fee-setting',
            children: [
              {
                path: '',
                name: 'feeSetting',
                component: () => import('@/views/dynamic-card.vue'),
                meta: {
                  pageTitle: 'Fees',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'general-setting',
                name: 'feeGeneralSetting',
                component: () => import('@/views/finance/fees/fees-setting/general-setting.vue'),
                meta: {
                  pageTitle: 'Fees General Settings',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },

              {
                path: 'fee-templates',
                name: 'feeTemplates',
                component: () => import('@/views/finance/fees/fees-setting/fee-template.vue'),
                meta: {
                  pageTitle: 'Fees Financial Template',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },

              {
                path: 'fee-receipt-and-voucher',
                name: 'feeReceiptAndVoucher',
                component: () => import('@/views/receipt-and-voucher/fee-receipt-and-voucher.vue'),
                meta: {
                  pageTitle: 'Fee Receipt and Voucher',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              }
            ]
          },
          {
            path: 'schedule-fee-collection',
            children: [
              {
                path: '',
                name: 'scheduleFeeCollection',
                component: () => import('@/views/dynamic-card.vue'),
                meta: {
                  pageTitle: 'Schedule Fee Collection',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'fee-collection',
                children: [
                  {
                    path: '',
                    name: 'feeCollection',
                    component: () =>
                      import(
                        '@/views/finance/fees/manage-schedule-fee-collection/fee-collection.vue'
                      ),
                    meta: {
                      pageTitle: 'Create Fee Collection',
                      requireAuthorization: true,
                      permission: ['admin', 'employee']
                    }
                  },
                  {
                    path: 'batch-fee-collection/:feeCollectionId?/:feeCategoryID?/',
                    children: [
                      {
                        path: '',
                        name: 'batchFeeCollection',
                        props: true,
                        component: () =>
                          import(
                            '@/views/finance/fees/manage-schedule-fee-collection/batch-fee-collection.vue'
                          ),
                        meta: {
                          pageTitle: 'Fee Collection Batch',
                          requireAuthorization: true,
                          permission: ['admin', 'employee']
                        }
                      },
                      {
                        path: 'manage-fee-collection/:sectionId?/',
                        name: 'manageBatchFeeCollection',
                        props: true,
                        component: () =>
                          import(
                            '@/views/finance/fees/manage-schedule-fee-collection/manage-fee-collection.vue'
                          ),
                        meta: {
                          pageTitle: 'Manage Fee Collection',
                          requireAuthorization: true,
                          permission: ['admin', 'employee']
                        }
                      }
                    ]
                  }
                ]
              },
              {
                path: 'manage-fee-collection',
                name: 'manageFeeCollection',
                component: () =>
                  import(
                    '@/views/finance/fees/manage-schedule-fee-collection/manage-fee-collection.vue'
                  ),
                meta: {
                  pageTitle: 'Manage Fee Collection',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              }
            ]
          },
          {
            path: 'collect-fees',
            children: [
              {
                path: '',
                name: 'collectFees',
                component: () => import('@/views/dynamic-card.vue'),
                meta: {
                  pageTitle: 'Collect Fees',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'fee-submission-batch',
                name: 'feeSubmissionBatch',
                component: () =>
                  import('@/views/finance/fees/collect-the-fees/fee-submission-batch.vue'),
                meta: {
                  pageTitle: 'Fee Submission Batch',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'fee-submission-student',
                children: [
                  {
                    path: '',
                    name: 'feeSubmissionStudent',
                    component: () =>
                      import('@/views/finance/fees/collect-the-fees/fee-submission-student.vue'),
                    meta: {
                      pageTitle: 'Fee Submission Batch',
                      requireAuthorization: true,
                      permission: ['admin', 'employee']
                    }
                  },
                  {
                    path: 'collection-wise/:studentId?/:studentName?/',
                    name: 'collectionWise',
                    props: true,
                    component: () =>
                      import('@/views/finance/fees/collect-the-fees/fee-submission-batch.vue'),
                    meta: {
                      pageTitle: 'Fee Submission for Student Collection Fees',
                      requireAuthorization: true,
                      permission: ['admin', 'employee']
                    }
                  },
                  {
                    path: 'pay-all-fees/:studentBatchId?/',
                    name: 'payAllFees',
                    props: true,
                    component: () =>
                      import(
                        '@/views/finance/fees/collect-the-fees/fee-submission-pay-all-fee-student.vue'
                      ),
                    meta: {
                      pageTitle: 'Fee Submission Pay All Fee for Student',
                      requireAuthorization: true,
                      permission: ['admin', 'employee']
                    }
                  }
                ]
              }
            ]
          },
          /************************************************************
              @Routes Start Fee Detail
            ************************************************************/
          {
            path: 'fee-detail',
            children: [
              {
                path: '',
                name: 'feeDetail',
                component: () =>
                  import(
                    '@/views/finance/fees/fee-detail/program-or-level-fee-collection-detail.vue'
                  ),
                meta: {
                  pageTitle: ' Fee Details',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'level-fee/:programId?/',
                name: 'levelFeeDetail',
                props: true,
                component: () => import('@/views/finance/fees/fee-detail/fragments/level-fee.vue'),
                meta: {
                  pageTitle: 'Level Fee',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'level-fee/:programId?/batch-fee/:levelId?/',
                name: 'batchFeeDetail',
                props: true,
                component: () =>
                  import('@/views/finance/fees/fee-detail/fragments/batch/batch-fee.vue'),
                meta: {
                  pageTitle: 'Batch Fee',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              /************************************************************
              @Routes Start Program OFF Collection Detail
              ************************************************************/
              {
                path: 'level-fee/batch-fee/:levelId?/batch-individual-collection/:sectionId?/',
                name: 'feeCollectionByBatch',
                props: true,
                component: () =>
                  import(
                    '@/views/finance/fees/fee-detail/fragments/batch/batch-individual-collection.vue'
                  ),
                meta: {
                  pageTitle: 'Batch Individual Collection',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'level-fee/batch-fee/:levelId?/batch-individual-collection/:sectionId?/student-individual-collection/:collectionId?/',
                name: 'studentWiseFeeDetailByBatch',
                props: true,
                component: () =>
                  import(
                    '@/views/finance/fees/fee-detail/fragments/student-individual-collection.vue'
                  ),
                meta: {
                  pageTitle: 'Student Individual Collection',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'level-fee/batch-fee/:levelId?/batch-students-collection/:sectionID?/',
                name: 'studentsFeeDetailByBatch',
                props: true,
                component: () =>
                  import(
                    '@/views/finance/fees/fee-detail/fragments/batch/batch-students-collection.vue'
                  ),
                meta: {
                  pageTitle: 'Batch Students Collection',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'level-fee/batch-fee/:levelId?/batch-detailed-collection/:sectionID?/',
                name: 'collectionsFeeDetailByBatch',
                props: true,
                component: () => import('@/views/finance/fees/fee-detail/detailed-collection.vue'),
                meta: {
                  pageTitle: 'Batch Detailed Collection',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              /************************************************************
              @Routes Start Program ON Collection Detail
              ************************************************************/
              {
                path: 'level-fee/:programId?/batch-fee/:levelId?/section-fee/:batchId?/',
                name: 'sectionFeeDetail',
                props: true,
                component: () =>
                  import('@/views/finance/fees/fee-detail/fragments/section/section-fee.vue'),
                meta: {
                  pageTitle: 'Section Fee',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'level-fee/:programId?/batch-fee/:levelId?/section-fee/:batchId?/section-students-collection/:sectionID?/',
                name: 'studentsFeeDetailBySection',
                props: true,
                component: () =>
                  import(
                    '@/views/finance/fees/fee-detail/fragments/section/section-students-collection.vue'
                  ),
                meta: {
                  pageTitle: 'Section Students Collection',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'level-fee/:programId?/batch-fee/:levelId?/section-fee/:batchId?/section-detailed-collection/:sectionID?/',
                name: 'collectionsFeeDetailBySection',
                props: true,
                component: () => import('@/views/finance/fees/fee-detail/detailed-collection.vue'),
                meta: {
                  pageTitle: 'Section Detailed Collection',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'level-fee/:programId?/batch-fee/:levelId?/section-fee/:batchId?/section-individual-collection/:sectionId?/',
                name: 'feeCollectionBySection',
                props: true,
                component: () =>
                  import(
                    '@/views/finance/fees/fee-detail/fragments/section/section-individual-collection.vue'
                  ),
                meta: {
                  pageTitle: 'Section Individual Collection',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'level-fee/:programId?/batch-fee/:levelId?/section-fee/:batchId?/section-individual-collection/:sectionId?/student-individual-collection/:collectionId?/',
                name: 'studentWiseFeeDetailBySection',
                props: true,
                component: () =>
                  import(
                    '@/views/finance/fees/fee-detail/fragments/student-individual-collection.vue'
                  ),
                meta: {
                  pageTitle: 'Student Individual Collection',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              }
            ]
          },
          /************************************************************
              @Routes Start Fee Report
            ************************************************************/
          {
            path: 'fee-report',
            children: [
              {
                path: '',
                name: 'feeReport',
                component: () => import('@/views/finance/fees/fee-reports/fee-report.vue'),
                meta: {
                  pageTitle: 'Fee Report',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'student-wise-fee-report/:studentWiseFeeReportid/',
                name: 'studentWiseFeeReport',
                component: () =>
                  import('@/views/finance/fees/fee-reports/student-wise-fee-report.vue'),
                meta: {
                  pageTitle: 'Student Wise Fee Report',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              }
            ]
          },
          {
            path: 'receipt-detail',
            name: 'receiptDetail',
            component: () => import('@/views/finance/fees/receipt/receipt-detail.vue'),
            meta: {
              pageTitle: 'Receipt Detail',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'fee-import',
            name: 'feeImport',
            component: () => import('@/views/finance/fees/fee-import/fee-import.vue'),
            meta: {
              pageTitle: 'Fee Import',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'opening-balance',
            name: 'openingBalance',
            component: () => import('@/views/finance/fees/opening-balance/opening-balance.vue'),
            meta: {
              pageTitle: 'Opening Balance Category',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'opening-balance/fee-management/:categoryId?/:isOpeningBalance?/',
            name: 'openingBalanceFeeManagement',
            props: true,
            component: () => import('@/views/finance/fees/create-fees/fee-management.vue'),
            meta: {
              pageTitle: 'Fee Management',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'opening-balance/fee-management/:categoryId?/:isOpeningBalance?/view-particular/:particularsId?/:batchName?/',
            name: 'openingBalanceViewParticular',
            props: true,
            component: () => import('@/views/finance/fees/create-fees/view-particular.vue'),
            meta: {
              pageTitle: 'View Particular',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },
      {
        path: 'tax-index',
        children: [
          {
            path: '',
            name: 'taxIndex',
            component: () => import('@/views/dynamic-card.vue'),
            meta: {
              pageTitle: 'Tax Index',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'tax-slab',
            name: 'taxSlab',
            component: () => import('@/views/finance/tax/tax-slab.vue'),
            meta: {
              pageTitle: 'Tax Slab',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'tax-setting',
            name: 'taxSetting',
            component: () => import('@/views/finance/tax/tax-setting.vue'),
            meta: {
              pageTitle: 'Tax Slab',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },

      {
        path: 'categories',
        name: 'CategoryType',
        children: [
          {
            path: 'categorytype',
            name: 'categorytype',
            component: () => import('@/views/finance/categories/category-type.vue'),
            meta: {
              pageTitle: 'Finance Category Type',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'category',
            name: 'category',
            component: () => import('@/views/finance/categories/category.vue'),
            meta: {
              pageTitle: 'Finance Category',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },

      {
        path: 'finance-main',
        children: [
          ...financeSummary,

          ...financeChartofAccount,

          {
            path: 'financial-year',
            name: 'feeFinancialYear',
            component: () =>
              import('@/views/finance/finance-main/manage-financial-year/financial-year.vue'),
            meta: {
              pageTitle: 'Financial Year',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'finance-template',
            name: 'financeTemplate',
            component: () => import('@/views/finance/finance-main/finance-template.vue'),
            meta: {
              pageTitle: 'Finance Template',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'tax-code',
            name: 'taxCode',
            component: () => import('@/views/finance/finance-main/tax-code.vue'),
            meta: {
              pageTitle: 'Tax-codes',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'finance-payment-modes',
            name: 'financePaymentModes',
            component: () => import('@/views/finance/finance-main/payment-modes.vue'),
            meta: {
              pageTitle: 'Payment Modes',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'bank-and-cash-account',
            name: '',
            children: [
              {
                path: '',
                name: 'bankAndCashAccount',
                component: () => import('@/views/finance/finance-main/bank-and-cash-account.vue'),
                meta: {
                  pageTitle: 'Finance Bank Cash Account',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'ledger/:accountID/',
                name: 'bankAndCashAccountGroupLedger',
                component: () =>
                  import(
                    '@/views/finance/finance-main/chart-of-account/chart-of-account-ledger.vue'
                  ),
                meta: {
                  pageTitle: 'Chart of Account Group Ledger',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              }
            ]
          },
          {
            path: 'receipt-and-voucher-summary',
            children: [
              {
                path: '',
                name: 'financeMainReceiptAndVoucherSummary',
                component: () =>
                  import(
                    '@/views/receipt-and-voucher/finance-main-receipt-and-voucher-summary.vue'
                  ),
                meta: {
                  pageTitle: 'FM Receipt and Voucher Summary',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'finance-main-receipt-and-voucher',
                name: 'financeMainReceiptVoucher',
                component: () =>
                  import('@/views/receipt-and-voucher/finance-main-receipt-and-voucher.vue'),
                meta: {
                  pageTitle: 'FM Receipt and Voucher',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              }
            ]
          },
          {
            path: 'transaction-management',
            children: [
              {
                path: '',
                name: 'transactionManagement',
                component: () => import('@/views/dynamic-card.vue'),
                meta: {
                  pageTitle: 'Transaction Management',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'receipt',
                name: 'receipt',
                component: () =>
                  import('@/views/finance/finance-main/transaction-management/receipt.vue'),
                meta: {
                  pageTitle: 'Receipt',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'payment',
                name: 'payment',
                component: () =>
                  import('@/views/finance/finance-main/transaction-management/payment.vue'),
                meta: {
                  pageTitle: 'Payment',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'inter-account-transfer',
                name: 'interAccountTransfer',
                component: () =>
                  import(
                    '@/views/finance/finance-main/transaction-management/inter-account-transfer.vue'
                  ),
                meta: {
                  pageTitle: 'Inter Account Transfer',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'expense',
                name: 'expense',
                component: () =>
                  import('@/views/finance/finance-main/transaction-management/expense.vue'),
                meta: {
                  pageTitle: 'Expense',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'income',
                name: 'income',
                component: () =>
                  import('@/views/finance/finance-main/transaction-management/income.vue'),
                meta: {
                  pageTitle: 'Income',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'subsidiary-book',
                name: 'subsidiaryBook',
                component: () =>
                  import('@/views/finance/finance-main/transaction-management/subsidiary-book.vue'),
                meta: {
                  pageTitle: 'Subsidiary Book',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              }
            ]
          }
        ]
      }
    ]
  }
]
