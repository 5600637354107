export default [
  {
    path: 'finance-report',
    children: [
      {
        path: '',
        name: 'financeReport',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Finance Report',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'outstanding-balance',
        name: 'outstandingBalance',
        component: () => import('@/views/report/finance-report/outstanding-balance.vue'),
        meta: {
          pageTitle: 'Outstanding Balance',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'defaulter-detail',
        name: 'defaulterDetail',
        component: () => import('@/views/report/finance-report/defaulter-detail.vue'),
        meta: {
          pageTitle: 'Defaulter Detail',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'fee-synopsis',
        name: 'feeSynopsis',
        component: () => import('@/views/report/finance-report/fee-synopsis.vue'),
        meta: {
          pageTitle: 'Fee Synopsis',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      }
    ]
  }
]
