export default [
  {
    path: 'academic-management-report',
    children: [
      {
        path: '',
        name: 'academicManagementReport',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Academic Managment Report',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'programs',
        name: '',
        children: [
          {
            path: '',
            name: 'allProgram',
            component: () =>
              import('@/views/report/academic-management/program-report/programs.vue'),
            meta: {
              pageTitle: 'Programs',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'level/:programId?/',
            name: 'programsOnLevel',
            component: () =>
              import('@/views/report/academic-management/program-report/programs-level.vue'),
            meta: {
              pageTitle: 'Programs Level',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'level/:programId/batch/:levelId?/',
            name: 'programsOnBatch',
            component: () =>
              import('@/views/report/academic-management/program-report/programs-batch.vue'),
            meta: {
              pageTitle: 'Programs Batch',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'level/:programId?/batch/:levelId?/section/:batchId?/',
            name: 'programsOnSection',
            component: () =>
              import('@/views/report/academic-management/program-report/programs-section.vue'),
            meta: {
              pageTitle: 'Programs Section',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'overview/:actionFrom?/:actionType?/:actionId?/',
            name: 'reportProgramOverview',
            component: () => import('@/views/report/academic-management/report-overview.vue'),
            meta: {
              pageTitle: 'Report Overview',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },
      {
        path: 'levels',
        name: '',
        children: [
          {
            path: '',
            name: 'allLevel',
            component: () => import('@/views/report/academic-management/level-report/levels.vue'),
            meta: {
              pageTitle: 'Levels',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'batch/:levelId?/',
            name: 'levelsOnBatch',
            component: () =>
              import('@/views/report/academic-management/level-report/levels-batch.vue'),
            meta: {
              pageTitle: 'Levels Batch',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'batch/:levelId?/section/:batchId?/',
            name: 'levelsOnSection',
            component: () =>
              import('@/views/report/academic-management/level-report/levels-section.vue'),
            meta: {
              pageTitle: 'Levels Section',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'overview/:actionFrom?/:actionType?/:actionId?/',
            name: 'reportLevelOverview',
            component: () => import('@/views/report/academic-management/report-overview.vue'),
            meta: {
              pageTitle: 'Report Overview',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },
      {
        path: 'batches',
        name: '',
        children: [
          {
            path: '',
            name: 'allBatch',
            component: () => import('@/views/report/academic-management/batch-report/batches.vue'),
            meta: {
              pageTitle: 'Batches',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'section/:batchId?/',
            name: 'batchOnSection',
            component: () =>
              import('@/views/report/academic-management/batch-report/batches-section.vue'),
            meta: {
              pageTitle: 'Batches Section',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'overview/:actionFrom?/:actionType?/:actionId?/',
            name: 'reportBatchOverview',
            component: () => import('@/views/report/academic-management/report-overview.vue'),
            meta: {
              pageTitle: 'Report Overview',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },
      {
        path: 'sections',
        name: '',
        children: [
          {
            path: '',
            name: 'allSection',
            component: () =>
              import('@/views/report/academic-management/section-report/sections.vue'),
            meta: {
              pageTitle: 'Sections',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'overview/:actionFrom?/:actionType?/:actionId?/',
            name: 'reportSectionOverview',
            component: () => import('@/views/report/academic-management/report-overview.vue'),
            meta: {
              pageTitle: 'Report Overview',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      }
    ]
  }
]
