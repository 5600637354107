export default [
  {
    path: 'finance-summary',
    children: [
      {
        path: '',
        name: 'financeSummary',
        component: () => import('@/views/finance/finance-main/finance-summary/finance-summary.vue'),
        meta: {
          pageTitle: 'Finance Summary',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'group/:parentID?/',
        children: [
          {
            path: '',
            name: 'financeSummaryGroup',

            component: () =>
              import('@/views/finance/finance-main/finance-summary/finance-summary-group.vue'),
            meta: {
              pageTitle: 'Summary Group',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'ledger/:accountID/',
            name: 'financeSummaryGroupLedger',

            component: () =>
              import('@/views/finance/finance-main/finance-summary/finance-summary-ledger.vue'),
            meta: {
              pageTitle: 'Summary Group Ledger',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },

      {
        path: 'ledger/:accountID/',
        name: 'financeSummaryLedger',
        component: () =>
          import('@/views/finance/finance-main/finance-summary/finance-summary-ledger.vue'),
        meta: {
          pageTitle: 'Summary Ledger',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      }
    ]
  }
]
