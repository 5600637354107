import transportFeeControlRoute from './transport-fee-control.route.js'
export default [
  {
    path: '/transport',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    children: [
      {
        path: '',
        name: 'transport',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Transport',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'settings',
        name: '',
        children: [
          {
            path: '',
            name: 'transportSetting',
            component: () => import('@/views/dynamic-card.vue'),
            meta: {
              pageTitle: 'Transport Settings',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'basic-setting',
            children: [
              {
                path: '',
                name: 'basicSetting',
                component: () => import('@/views/transport/setting/basic-setting.vue'),
                meta: {
                  pageTitle: 'Transport Basic Settings',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'profile-details/:profileId?/',
                name: 'basicSettingsDetails',
                props: true,
                component: () => import('@/views/transport/setting/view-basic-setting-detail.vue'),
                meta: {
                  pageTitle: 'Transport Basic Settings Detail',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              }
            ]
          },
          {
            path: 'route-additional-detail',
            name: 'routeAdditionalDetail',
            component: () => import('@/views/transport/setting/route-additional-detail.vue'),
            meta: {
              pageTitle: 'Transport Route Additional Detail',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'vehicle-additional-detail',
            name: 'vehicleAdditionalDetail',
            component: () => import('@/views/transport/setting/vehicle-additional-detail.vue'),
            meta: {
              pageTitle: 'Transport Vehicle Additional Detail',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'vehicle-certificate-type',
            name: 'vehicleCertificateType',
            component: () => import('@/views/transport/setting/vehicle-certificate-type.vue'),
            meta: {
              pageTitle: 'Transport Vehicle Certificate Type',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },

      {
        path: 'stop-detail',
        name: 'stopDetail',
        component: () => import('@/views/transport/stop-detail.vue'),
        meta: {
          pageTitle: 'Transport Stop Details',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'vehicle',
        name: '',
        children: [
          {
            path: '',
            name: 'vehicleDetail',
            component: () => import('@/views/transport/vehicle-details/vehicle-details.vue'),
            meta: {
              pageTitle: 'Transport Vehicles',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'vehicle-detail/:vehicleId/',
            name: '',
            children: [
              {
                path: '',
                name: 'viewVehicleDetail',
                component: () =>
                  import('@/views/transport/vehicle-details/view-vehicle-detail.vue'),
                meta: {
                  pageTitle: 'Transport Vehicle Details',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              },
              {
                path: 'vehicle-certificate',
                name: 'vehicleCertificate',
                props: true,
                component: () =>
                  import('@/views/transport/vehicle-details/vehicle-certificate.vue'),
                meta: {
                  pageTitle: 'Transport Vehicle Certificates',
                  requireAuthorization: true,
                  permission: ['admin', 'employee']
                }
              }
            ]
          }
        ]
      },

      {
        path: 'driver-and-attendant',
        name: 'driverAndAttendant',
        component: () => import('@/views/transport/driver-and-attendant.vue'),
        meta: {
          pageTitle: 'Transport Driver And Attendant',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'vehicle-route',
        name: '',
        children: [
          {
            path: '',
            name: 'vehicleRoute',
            component: () => import('@/views/transport/vehicle-route/vehicle-route.vue'),
            meta: {
              pageTitle: 'Vehicle Route',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'add-route',
            name: 'addVehicleRoute',
            component: () => import('@/views/transport/vehicle-route/add-route.vue'),
            meta: {
              pageTitle: 'Add Transport Route ',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'view-route/:vehicleRouteId/',
            name: 'editVehicleRoute',
            props: true,
            component: () => import('@/views/transport/vehicle-route/add-route.vue'),
            meta: {
              pageTitle: 'View Route',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },

      {
        path: 'assign-transport-route',
        name: '',
        children: [
          {
            path: '',
            name: 'assignTransportRoute',
            component: () =>
              import('@/views/transport/manage-assign-route/assign-transport-route.vue'),
            meta: {
              pageTitle: 'Assign Transport Route',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'assign-route-history/:userid/',
            name: 'assignRouteHistory',
            props: true,
            component: () =>
              import('@/views/transport/manage-assign-route/assign-route-history.vue'),
            meta: {
              pageTitle: 'Assign Route History',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },

      ...transportFeeControlRoute,

      {
        path: 'transport-attendance',
        name: 'transportAttendance',
        component: () => import('@/views/transport/transport-attendance.vue'),
        meta: {
          pageTitle: 'Transport Attendance',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'vehicle-maintenance',
        name: '',
        children: [
          {
            path: '',
            name: 'transportMaintenance',
            component: () =>
              import('@/views/transport/vehicle-maintenance/vehicle-maintenance.vue'),
            meta: {
              pageTitle: 'Transport Maintenance',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'vehicle-maintenance-detail/:maintenanceId?/',
            name: 'transportMaintenanceDetail',
            props: true,
            component: () =>
              import('@/views/transport/vehicle-maintenance/vehicle-maintenance-detail.vue'),
            meta: {
              pageTitle: 'Transport Maintenance Details',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },

      {
        path: '/transport/transport-report',
        children: [
          {
            path: '',
            name: 'transportOverallReport',
            component: () => import('@/views/dynamic-card.vue'),
            meta: {
              pageTitle: 'Transport Report',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: ':type',
            name: 'transportReport',
            props: true,
            component: () =>
              import('@/views/transport/manage-transport-report/transport-report.vue'),
            meta: {
              pageTitle: 'Transport Report',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      }
    ]
  }
]
