export default [
  {
    path: 'attendance-report',
    children: [
      {
        path: '',
        name: 'attendanceReport',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Attendance Report',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'student-attendance-report',
        children: [
          {
            path: '',
            name: 'studentAttendanceReport',
            component: () => import('@/views/dynamic-card.vue'),
            meta: {
              pageTitle: 'Student Attendance Report',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'student-daily-attendance',
            name: 'studentDailyAttendance',
            component: () =>
              import('@/views/report/attendance-report/student/student-daily-attendance.vue'),
            meta: {
              pageTitle: 'Student Daily Attendance',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'student-overall-attendance',
            name: 'studentOverallAttendance',
            component: () =>
              import('@/views/report/attendance-report/student/student-overall-attendance.vue'),
            meta: {
              pageTitle: 'Student Overall Attendance',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'student-date-wise-attendance',
            name: 'studentDateWiseAttendance',
            component: () =>
              import('@/views/report/attendance-report/student/student-date-wise-attendance.vue'),
            meta: {
              pageTitle: 'Student Date Wise Attendance',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },
      {
        path: 'employee-attendance-report',
        children: [
          {
            path: '',
            name: 'employeeAttendanceReport',
            component: () => import('@/views/dynamic-card.vue'),
            meta: {
              pageTitle: 'Employee Attendance Report',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'employee-daily-attendance',
            name: 'employeeDailyAttendance',
            component: () =>
              import('@/views/report/attendance-report/employee/employee-daily-attendance.vue'),
            meta: {
              pageTitle: 'Employee Daily Attendance',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'employee-overall-attendance',
            name: 'employeeOverallAttendance',
            component: () =>
              import('@/views/report/attendance-report/employee/employee-overall-attendance.vue'),
            meta: {
              pageTitle: 'Employee Overall Attendance',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'employee-date-wise-attendance',
            name: 'employeeDateWiseAttendance',
            component: () =>
              import('@/views/report/attendance-report/employee/employee-date-wise-attendance.vue'),
            meta: {
              pageTitle: 'Employee Date Wise Attendance',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      }
    ]
  }
]
