import cookiesStorage from 'js-cookie'
import axios from 'axios'
import router from '@/router'
import { decodeHexa } from './crypto.utils.js'

export function checkAuthentication() {
  try {
    let token = cookiesStorage.get('_zyml_jt')
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${decodeHexa(token)}`
      return true
    } else {
      delete axios.defaults.headers.common['Authorization']
      return false
    }
  } catch (error) {
    delete axios.defaults.headers.common['Authorization']
    return false
  }
}
async function removeStorage(cookiesToRemove = [], isRemoveLocalStorage = true) {
  try {
    if (isRemoveLocalStorage) await removeAllLocalStorage()
    cookiesToRemove.forEach((cookieName) => {
      cookiesStorage.remove(cookieName)
    })
  } catch (error) {
    console.error('Error in removeStorage', error)
    throw error
  }
}
async function removeAllLocalStorage() {
  try {
    Object.keys(localStorage).forEach(async (key) => {
      if (key != '_zyml_instpblcdts') localStorage.removeItem(key)
    })
    return
  } catch (error) {
    console.error('Error in removeAllLocalStorage', error)
    throw error
  }
}
export async function removeCookies(statusCode = null, removeCookie = null, errorMessage = null) {
  if (removeCookie)
    switch (removeCookie) {
      case 'jwt':
        cookiesStorage.remove('_zyml_jt')
        break
      case 'user':
        cookiesStorage.remove('_zyml_usrnm')
        break
      case 'pass':
        cookiesStorage.remove('_zyml_upd')
        break
    }
  else await removeStorage(['_zyml_jt', '_zyml_usrnm', '_zyml_upd'], false)
  await navigateUserByStatusCode(statusCode, errorMessage)
}
async function navigateUserByStatusCode(statusCode, errorMessage = null) {
  try {
    const messages = {
      200: 'Success',
      401: 'Unauthorized',
      403: 'Forbidden',
      404: 'Path Not Found',
      408: 'Request Timeout',
      500: 'Internal Server Error'
    }
    const message = errorMessage || messages[statusCode] || ''
    const query =
      statusCode !== 200 && message
        ? { message, code: statusCode, challenge: Date.now() }
        : { challenge: Date.now() }
    await removeStorage(['_zyml_usrnm', '_zyml_upd', '_zyml_mdprms'])
    await router.replace({
      name: 'redirectLoginPanel',
      query
    })
  } catch (error) {
    console.error('Error in navigateUserByStatusCode', error)
    throw error
  }
}

export function generateArrayofModuleActionPermission(modules) {
  const generatedObjects = []
  const setDefaultBoolean = (object, actionName, value) => {
    object[actionName] = value
  }

  modules.forEach((module) => {
    const { ash_ModuleId, ash_Name, ash_ActionName, inverseAsh_Parent, ash_Permission } = module

    const generatedObject = {
      moduleID: ash_ModuleId,
      moduleName: ash_Name,
      [ash_ActionName]: false
    }

    if (ash_Permission && ash_Permission.ash_IsRead) generatedObject[ash_ActionName] = true

    if (ash_Permission && ash_Permission.ash_IsRead && ash_Permission.ash_IsWrite)
      setDefaultBoolean(generatedObject, ash_ActionName, true)

    if (ash_Permission && ash_Permission.ash_IsWrite && inverseAsh_Parent.length > 0) {
      inverseAsh_Parent.forEach((parent) => {
        const { ash_ActionName: parentName, ash_Permission: parentPermission } = parent

        setDefaultBoolean(generatedObject, parentName, false)

        generatedObject[parentName] = parentPermission && parentPermission.ash_IsWrite
      })
    }

    generatedObjects.push(generatedObject)
  })
  return generatedObjects
}

export function updatePermissions(obj, updatedPermissions) {
  const stack = [obj]

  while (stack.length > 0) {
    const currentObj = stack.pop()

    if (currentObj && typeof currentObj === 'object') {
      if (currentObj.ash_Permission && currentObj.ash_Permission.ash_Id) {
        const matchingPermission = updatedPermissions.find(
          (p) => p.ash_Id === currentObj.ash_Permission.ash_Id
        )
        if (matchingPermission) {
          Object.assign(currentObj.ash_Permission, matchingPermission)
        }
      }

      if (currentObj.inverseAsh_Parent && Array.isArray(currentObj.inverseAsh_Parent)) {
        currentObj.inverseAsh_Parent.forEach((parent) => {
          stack.push(parent)
        })
      }

      Object.values(currentObj).forEach((value) => {
        stack.push(value)
      })
    }
  }
}
export function generateArithmeticQuestion() {
  let generatedQuestions = []
  const maxNumber = 9
  const num1 = Math.floor(Math.random() * maxNumber) + 1
  let num2 = Math.floor(Math.random() * maxNumber) + 1
  let operator = ''

  const operatorChoice = Math.floor(Math.random() * 4)
  switch (operatorChoice) {
    case 0:
      operator = '+'
      break
    case 1:
      operator = '-'
      break
    case 2:
      operator = '*'
      break
    case 3:
      operator = '/'
      while (num1 % num2 !== 0) {
        num2 = Math.floor(Math.random() * maxNumber) + 1
      }
      break
  }

  let answer
  switch (operator) {
    case '+':
      answer = num1 + num2
      break
    case '-':
      answer = num1 - num2
      break
    case '*':
      answer = num1 * num2
      break
    case '/':
      answer = num1 / num2
      break
  }

  const question = `${num1} ${operator} ${num2} = ?`

  if (generatedQuestions.includes(question)) {
    return generateArithmeticQuestion()
  }

  generatedQuestions.push(question)

  return { question, answer }
}

export function generateDistortion(svgText) {
  return `<svg xmlns="http://www.w3.org/2000/svg" width="160" height="40">
    <defs>
      <filter id="scratch">
        <feTurbulence type="fractalNoise" baseFrequency="0.02" numOctaves="3" result="turbulence"/>
        <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="10" xChannelSelector="R" yChannelSelector="G"/>
      </filter>
      <filter id="blur">
        <feGaussianBlur in="SourceGraphic" stdDeviation="0.5"/>
      </filter>
      <pattern id="lattice" width="10" height="10" patternUnits="userSpaceOnUse" opacity="0.1">
        <path d="M0 0 L10 0 L10 10 L0 10 Z" fill="none" stroke="black" stroke-width="0.5"/>
        <path d="M0 5 L10 5 M5 0 L5 10" stroke="black" stroke-width="0.5"/>
      </pattern>
    </defs>
    <rect width="160" height="40" fill="url(#lattice)"/>
    <text x="10" y="25" font-size="24" filter="url(#scratch) url(#blur)" fill="black">${svgText}</text>
  </svg>`
}

export async function isValidJwtToken(token) {
  try {
    return JSON.parse(atob(token.split('.')[1])).exp * 1000 > Date.now()
  } catch (error) {
    return false
  }
}
