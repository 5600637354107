export default [
  {
    path: '/alumni',
    name: '',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    children: [
      {
        path: '',
        name: 'alumni',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Alumni',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },

      {
        path: 'alumni-student',
        name: '',
        children: [
          {
            path: '',
            name: 'studentAlumni',
            component: () => import('@/views/dynamic-card.vue'),
            meta: {
              pageTitle: 'Student Alumni',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'student-alumni',
            name: 'alumniStudent',
            component: () => import('@/views/collaboration/alumni/student/student-alumni.vue'),
            meta: {
              pageTitle: 'Student Alumni',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'student-transition-management',
            name: 'alumniTransitionManagement',
            component: () =>
              import('@/views/collaboration/alumni/student/student-transition-management.vue'),
            meta: {
              pageTitle: 'Student Transition Management',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'student-transition-management/student-transition-history',
            name: 'alumniTransitionHistory',
            component: () =>
              import('@/views/collaboration/alumni/student/student-transition-history.vue'),
            meta: {
              pageTitle: 'Alumni Transition History',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'alumni-transition-history-details/:code/',
            name: 'alumniTransitionHistoryDetails',
            component: () =>
              import('@/views/collaboration/alumni/student/student-transition-detail.vue'),
            meta: {
              pageTitle: 'Alumni Transition History Detail',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            },
            props: true
          }
        ]
      },

      {
        path: 'alumni-employee',
        name: '',
        children: [
          {
            path: '',
            name: 'employeeAlumni',
            component: () => import('@/views/dynamic-card.vue'),
            meta: {
              pageTitle: 'Employee Alumni',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'alumni-employees',
            name: 'alumniEmployee',
            component: () => import('@/views/collaboration/alumni/employee/employee-alumni.vue'),
            meta: {
              pageTitle: 'Employee Alumni',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'employee-transition-management',
            name: 'employeeTransitionManagement',
            component: () =>
              import('@/views/collaboration/alumni/employee/employee-transition-management.vue'),
            meta: {
              pageTitle: 'Employee Transition Management',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'employee-transition-management/employee-transition-history',
            name: 'employeeTransitionHistory',
            component: () =>
              import('@/views/collaboration/alumni/employee/employee-transition-history.vue'),
            meta: {
              pageTitle: 'Employee Transition History',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },

          {
            path: 'employee-transition-history-details/:code/',
            name: 'employeeTransitionHistoryDetails',
            component: () =>
              import('@/views/collaboration/alumni/employee/employee-transition-detail.vue'),
            meta: {
              pageTitle: 'Employee Transition History Detail',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            },
            props: true
          }
        ]
      }
    ]
  }
]
