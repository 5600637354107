export default [
  {
    path: 'academic-transition-management',
    name: 'academicTransitionManagement',
    component: () =>
      import(
        '@/views/academic-management/transition-management/academic-transition-management.vue'
      ),
    meta: {
      pageTitle: 'Academic Transition Management',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },
  {
    path: 'academic-transition-management/student-transition-history',
    name: 'studentTransitionHistory',
    component: () =>
      import('@/views/academic-management/transition-management/student-transition-history.vue'),
    meta: {
      pageTitle: 'Student Transition History',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },
  {
    path: 'academic-transition-management/student-transition-history/student-transition-history-detail/:id/',
    name: 'studentTransitionHistoryDetails',
    component: () =>
      import(
        '@/views/academic-management/transition-management/student-transition-history-details.vue'
      ),
    meta: {
      pageTitle: 'Student Transition History',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    },
    props: true
  }
]
