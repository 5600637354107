export default [
  /***********************************************************************
   * @Start Routes From Program > Level > Batch > Section When Program is ON
   ***********************************************************************/
  {
    path: 'roll-number-program',
    name: 'rollNumberProgramONProgram',
    component: () =>
      import('@/views/academic-management/student-roll-number/student-roll-number-program.vue'),
    meta: {
      pageTitle: 'Roll Number Program',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'roll-number-program/roll-number-level/:levelid?/',
    name: 'rollNumberProgramONLevel',
    component: () =>
      import('@/views/academic-management/student-roll-number/student-roll-number-level.vue'),
    meta: {
      pageTitle: 'Roll Number Level',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'roll-number-program/roll-number-level/:programid?/roll-number-batch/:levelid?/',
    name: 'rollNumberProgramONBatch',
    component: () =>
      import('@/views/academic-management/student-roll-number/student-roll-number-batch.vue'),
    meta: {
      pageTitle: 'Roll Number Batch',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'roll-number-program/roll-number-level/:programid?/roll-number-batch/:levelid?/roll-number-section/:batchid?/',
    name: 'rollNumberProgramONSection',
    component: () =>
      import('@/views/academic-management/student-roll-number/student-roll-number-section.vue'),
    meta: {
      pageTitle: 'Roll Number Section',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  /***********************************************************************
   * @Start Routes From Level > Batch > Section When Program is OFF
   ***********************************************************************/
  {
    path: 'roll-number-level',
    name: 'rollNumberProgramOFFLevel',
    component: () =>
      import('@/views/academic-management/student-roll-number/student-roll-number-level.vue'),
    meta: {
      pageTitle: 'Roll Number Level',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  },

  {
    path: 'roll-number-level/roll-number-batch/:levelid?/',
    name: 'rollNumberProgramOFFBatch',
    component: () =>
      import('@/views/academic-management/student-roll-number/student-roll-number-batch.vue'),
    meta: {
      pageTitle: 'Roll Number Batch',
      requireAuthorization: true,
      permission: ['admin', 'employee']
    }
  }
]
