import BaseStyle from '../base/BaseStyle.js'

const classes = {
  root: 'p-ink'
}

export default BaseStyle.extend({
  name: 'ripple',
  classes
})
