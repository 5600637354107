export default [
  {
    path: '/user',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    children: [
      {
        path: '',
        name: 'user',
        component: () => import('@/views/user/user.vue'),
        meta: {
          pageTitle: 'User',
          requireAuthorization: true,
          permission: ['admin']
        }
      },

      {
        path: 'user-privilege/:userCode?/:userRoleId/',
        name: 'userPrivilege',
        component: () => import('@/views/user/user-privilege.vue'),
        meta: {
          pageTitle: 'User Privilege',
          requireAuthorization: true,
          permission: ['admin']
        }
      }
    ]
  }
]
