export default [
  {
    path: '/',
    name: 'redirectLoginPanel',
    redirect: '/auth/login'
  },

  {
    path: '/auth/login',
    name: 'verifyUser',
    component: () => import('@/authentication/login.vue'),
    meta: {
      pageTitle: 'Acado ERP | Login',
      guestOnly: true
    }
  }
]
